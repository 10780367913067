import { useEffect } from 'react'
import { useLocation } from 'react-router'

declare global {
  interface Window {
    // Appcues object itself is nullable in case
    // Appcues bundle is got cut by some extension like adblock
    Appcues?: {
      page: Function
      identify: (userId: string, additionalDetails?: any) => void
      track: (eventName: string, additionalDetails?: any) => void
    }
  }
}

export const useAppcues = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.Appcues?.page()
  }, [pathname])
}
