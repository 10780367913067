import React, { useCallback, useMemo, useState } from 'react'
import { Divider, Heading, Stack, useDisclosure } from '@chakra-ui/react'
import { Card, GenericError, GenericSpinner } from '@liveflow-io/component-common'
import { useEnchancedQuery } from '@liveflow-io/hooks-common'
import type { Unpacked } from '@liveflow-io/utils-common'
import { impossibleState, utcDayJs } from '@liveflow-io/utils-common'
import { last } from 'lodash'
import type { CashflowPage_BudgetQuery } from './documents.generated'
import { CashflowPage_BudgetDocument } from './documents.generated'
import { EditBudgetModal } from './EditBudgetModal'
import { CreateBudgetModal } from './CreateBudgetModal'
import { BudgetTable } from './BudgetTable'
import { DeleteBudgetModal } from './DeleteBudgetModal'

export const CashflowPage = () => {
  const [budgetState] = useEnchancedQuery({ query: CashflowPage_BudgetDocument })

  switch (budgetState.state) {
    case 'idle':
    case 'fetching':
      return <GenericSpinner />
    case 'error':
    case 'partial':
    case 'partial-stale':
      return <GenericError />
    case 'stale':
    case 'done':
      return <Cashflow data={budgetState.data} />
    default:
      return impossibleState(budgetState)
  }
}

export type BudgetType = Unpacked<NonNullable<CashflowPage_BudgetQuery['budget']>>

export const Cashflow = ({ data }: { data: CashflowPage_BudgetQuery }) => {
  const budgets = data.budget

  const {
    isOpen,
    onOpen: onCreateBudgetModalOpen,
    onClose: onCreateBudgetModalClose,
  } = useDisclosure()

  const [budgetToEdit, setBudgetToEdit] = useState<BudgetType | null | undefined>(null)
  const [budgetToDeleteId, setBudgetToDeleteId] = useState<string | undefined>()
  const onCloseBudgetEdit = useCallback(() => {
    setBudgetToEdit(null)
  }, [])
  const onBudgetEditClick = useCallback(
    (id: string) => {
      setBudgetToEdit(budgets.find((bg) => bg.id === id))
    },
    [budgets],
  )
  const onBudgetDeleteClick = useCallback(
    (id: string) => {
      setBudgetToDeleteId(budgets.find((bg) => bg.id === id)?.id)
    },
    [budgets],
  )

  const minMonth = useMemo(() => {
    return budgets.length > 0
      ? utcDayJs(last(budgets)?.startDate ?? '').add(1, 'months')
      : utcDayJs()
  }, [budgets])
  return (
    <>
      <Stack spacing={[4, 8]}>
        <Heading as="h1" size="lg">
          Cash flow
        </Heading>
        <Divider />
        <Heading as="h2" size="md">
          Click on +Add Budget to add your budget for a period
        </Heading>
        <Card overflow="auto">
          <BudgetTable
            onBudgetEditClick={onBudgetEditClick}
            onBudgetDeleteClick={onBudgetDeleteClick}
            onCreateBudgetModalOpen={onCreateBudgetModalOpen}
            budgets={budgets}
          />
        </Card>
      </Stack>
      <CreateBudgetModal
        isOpen={isOpen}
        onClose={onCreateBudgetModalClose}
        minMonth={minMonth}
      />
      <DeleteBudgetModal
        budgetToDeleteId={budgetToDeleteId}
        unsetBudgetToDeleteId={setBudgetToDeleteId}
      />
      {budgetToEdit && (
        <EditBudgetModal budget={budgetToEdit} onClose={onCloseBudgetEdit} />
      )}
    </>
  )
}
