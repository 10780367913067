import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Spinner, Stack, Text } from '@chakra-ui/react'
import { useToast, useEnchancedMutation } from '@liveflow-io/hooks-common'
import { TrackingService, INTEGRATION_EVENTS } from 'packlets/utils'
import { impossibleState, isNotNullish } from '@liveflow-io/utils-common'
import { Routes } from 'packlets/constants'
import { CodatRedirect_CodateIntegrationPayloadDocument } from './documents.generated'

export const CodatRedirect = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const [, integrateCodat] = useEnchancedMutation(
    CodatRedirect_CodateIntegrationPayloadDocument,
  )

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const companyId = urlParams.get('companyId')
    if (isNotNullish(companyId)) {
      integrateCodat({ input: { codatCompanyId: companyId } })
        .then((integrated) => {
          switch (integrated.state) {
            case 'error':
            case 'partial':
              break
            case 'done': {
              toast({
                title: 'Success!',
                description: 'Accounting integration added.',
              })
              TrackingService.track(INTEGRATION_EVENTS.INTEGRATED_ACCOUNTING_SUCCESS)
              navigate(Routes.INTEGRATIONS)
              break
            }
            default:
              impossibleState(integrated)
          }
          return integrated
        })
        .catch((e) => {
          console.error(e)
          toast({
            status: 'error',
            title: 'Something went wrong!',
            description: 'Network issues, or a timeout? Check console for more info.',
          })
          navigate(Routes.INTEGRATIONS)
        })
    }
  }, [integrateCodat, navigate, toast])

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      w="100vw"
      h="100vh"
      direction="column"
      spacing={8}
    >
      <Spinner size="xl" />
      <Text>
        Fetching data... Mind that it may take 2-3 minutes. Do not refresh the page. We
        will redirect you automatically.
      </Text>
    </Stack>
  )
}
