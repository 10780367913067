import type { ComponentWithAs, StackProps } from '@chakra-ui/react'
import { Box, Stack, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import { useLocation } from 'react-router'

interface NavItemInterface extends StackProps {
  label: string
  subtle?: boolean
  icon: React.ReactElement
  endElement?: React.ReactElement
}

type NavItemProps = ComponentWithAs<'div', NavItemInterface>

export const NavItem: NavItemProps = ({ subtle, icon, label, endElement, ...props }) => {
  const { pathname } = useLocation()
  const bg = useColorModeValue('gray.100', 'gray.700')
  const activeBgColor = useColorModeValue('gray.200', 'gray.600')

  const subtleColor = useColorModeValue('gray.600', 'gray.400')
  const color = useColorModeValue('gray.900', 'white')
  const activeColor = useColorModeValue('gray.700', 'gray.400')

  const active = props.to === pathname
  return (
    <Stack
      direction="row"
      w="full"
      px="3"
      py="2"
      cursor="pointer"
      userSelect="none"
      rounded="md"
      color={color}
      transition="all 0.2s"
      bg={active ? bg : undefined}
      _hover={{ bg }}
      _active={{ bg: activeBgColor }}
      {...props}
    >
      <Box fontSize="lg" color={active ? 'currentcolor' : activeColor}>
        {icon}
      </Box>
      <Box flex="1" fontWeight="inherit" color={subtle ? subtleColor : undefined}>
        {label}
      </Box>
      {endElement && <Box>{endElement}</Box>}
    </Stack>
  )
}
