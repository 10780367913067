export const getEnvByHostname = () => {
  const PROD_URL = process.env.REACT_APP_PROD_URL ?? ''

  if (window.location.hostname.toLowerCase() === PROD_URL) {
    return 'production'
  }
  return 'development'
}

export const isProduction = () =>
  window.location.hostname.toLowerCase() === process.env.REACT_APP_PROD_URL ?? ''
