export const Routes = {
  AUTH_REDIRECT: '/authRedirect',
  TRUELAYER_REDIRECT: '/truelayerRedirect',
  SIGN_IN: '/signIn',
  ONBOARDING: '/onboarding',
  BANK_DASHBOARD: '/bankDashboard',
  PROFIT_LOSS: '/profitLoss',
  CASH_FLOW: '/cashFlow',
  INTEGRATIONS: '/integrations',
  INVITE: '/invite',
  SETTINGS: '/settings',
  NOT_ENABLED: '/notEnabled',
  CODAT_REDIRECT: '/codatRedirect',
  CONSOLIDATED_PROFIT_LOSS: '/consolidatedProfitLoss',
} as const
