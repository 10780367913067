import { Card } from '@liveflow-io/component-common'
import { Stack, Button } from '@chakra-ui/react'
import * as React from 'react'
import { CardHeading } from './Settings'
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_JJ1eMdKN0Hp4UFJ6kWXWO4ix00jtXzq5XG')

export const PaymentInformation = () => (
  <Card maxW="3xl" w={{ base: 'full', lg: 'lg' }}>
    <CardHeading title="Payment information">
      (In test mode, do not use!) Everything related to your company billing
    </CardHeading>
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  </Card>
)

const CheckoutForm = () => {
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit: React.FormEventHandler = async (event) => {
    // Block native form submission.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement)

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement!,
    })

    if (error) {
      console.info('[error]', error)
    } else {
      console.info('[PaymentMethod]', paymentMethod)
    }
  }

  return (
    <Stack onSubmit={handleSubmit} mt={4}>
      <CardElement />
      <Button type="submit" disabled={!stripe}>
        Subscribe and Pay
      </Button>
    </Stack>
  )
}
