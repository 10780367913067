import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Select,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import {
  Card,
  GenericEmpty,
  GenericError,
  GenericSpinner,
} from '@liveflow-io/component-common'
import { IntegrateBankButton } from 'packlets/components'
import {
  useEnchancedQuery,
  useQueryCachedResultForDiffArgs,
} from '@liveflow-io/hooks-common'
import { impossibleState, isProduction, utcDayJs } from '@liveflow-io/utils-common'
import React, { useEffect, useMemo, useState } from 'react'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import type { Dashboard_DashboardQuery } from './documents.generated'
import { BalanceCards, Balances } from './Balances'
import { DashboardFilters } from './DashboardFilters'
import { Actuals } from './Actuals'
import { fakeBalance, fakeDashboard } from './fakeData'
import { atom, useAtom } from 'jotai'
import { IntegrationSource } from 'packlets/generated'
import { Dashboard_DashboardDocument } from './documents.generated'

export const dashboardFilterAtom = atom({
  from: utcDayJs().subtract(3, 'months').toGraphQLDate(),
  to: utcDayJs().toGraphQLDate(),
})

export const integrationSourceAtom = atom(IntegrationSource.Plaid)
export const filterChangeResultsFetchingAtom = atom(false)

export const amountOfMonthsSelectedAtom = atom((get) => {
  const { from, to } = get(dashboardFilterAtom)
  return (
    Math.abs(utcDayJs(from).startOf('month').diff(utcDayJs(to).endOf('month'), 'month')) +
    1
  )
})

const useHandleFilterChangeResultsLoading = (fetching: boolean) => {
  const setChangeResultsFetching = useUpdateAtom(filterChangeResultsFetchingAtom)
  useEffect(() => {
    void setChangeResultsFetching(fetching)
  }, [fetching, setChangeResultsFetching])
}

export const BankDashboardPage = () => {
  const { from, to } = useAtomValue(dashboardFilterAtom)
  const [integrationSource, setIntegrationSource] = useAtom(integrationSourceAtom)
  const [machine, refetch, originalState] = useEnchancedQuery({
    query: Dashboard_DashboardDocument,
    pollInterval: 60000,
    variables: {
      source: integrationSource,
      range: {
        from,
        to,
      },
    },
  })

  const memoized = useMemo(() => {
    return [machine, refetch]
  }, [machine, refetch])

  // @ts-expect-error
  const [dashboardMachine, refetchDashboard] = useQueryCachedResultForDiffArgs(memoized)

  useHandleFilterChangeResultsLoading(originalState.fetching)

  switch (dashboardMachine.state) {
    case 'idle':
    case 'fetching':
      return <GenericSpinner />
    case 'error':
    case 'partial':
    case 'partial-stale':
      return <GenericError />
    case 'stale':
    case 'done': {
      const { __typename } = dashboardMachine.data.dashboard
      return (
        <Stack spacing={[2, 4, 8]}>
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={[2, 4, 8]}
            alignItems="baseline"
            justifyContent="space-between"
          >
            <Heading as="h1" size="lg">
              Bank dashboard
            </Heading>
            <Text size="lg" fontWeight="bold">
              Got a question? Call us or send us a WhatsApp message on +44 7752 097547
            </Text>
          </Stack>
          <Divider />
          {(__typename === 'DashboardReady' || __typename === 'DashboardNoData') && (
            <Stack direction="row" spacing={8}>
              <DashboardFilters />
              <FormControl id="bank-region" w={200}>
                <FormLabel>Bank region</FormLabel>
                <Select
                  isDisabled={isProduction()}
                  placeholder="Select option"
                  value={integrationSource}
                  onChange={(e) =>
                    setIntegrationSource(e.target.value as IntegrationSource)
                  }
                >
                  <option value={IntegrationSource.Plaid}>United States</option>
                  <option value={IntegrationSource.TrueLayer}>United Kingdom</option>
                </Select>
              </FormControl>
            </Stack>
          )}
          <BankDashboard
            data={dashboardMachine.data}
            refetchDashboard={refetchDashboard}
          />
        </Stack>
      )
    }
    default:
      return impossibleState(dashboardMachine)
  }
}

type DashboardState =
  | { state: 'loaded' }
  | { state: 'integration-pending'; timeout: number }

export const BankDashboard = ({
  data,
  refetchDashboard,
}: {
  data: Dashboard_DashboardQuery
  refetchDashboard: any
}) => {
  const [dashboardState, setDashboardState] = useState<DashboardState>({
    state: 'loaded',
  })

  useEffect(() => {
    switch (dashboardState.state) {
      case 'integration-pending':
        setTimeout(() => {
          setDashboardState({ state: 'loaded' })
          refetchDashboard({ requestPolicy: 'cache-and-network' })
        }, dashboardState.timeout)
        break
      case 'loaded':
        break
      default:
        impossibleState(dashboardState)
    }
  }, [dashboardState, refetchDashboard])
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
  const bg = useColorModeValue('white', 'gray.800')
  const { dashboard } = data
  return (
    <Stack spacing={[2, 4, 8]}>
      {(() => {
        switch (dashboardState.state) {
          case 'integration-pending':
            return <GenericSpinner containerProps={{ h: '600px' }} />
          case 'loaded': {
            switch (dashboard.__typename) {
              case 'DashboardNoIntegrations':
                return (
                  <>
                    <Flex justifyContent="space-between">
                      <IntegrateBankButton
                        onSuccess={() =>
                          setDashboardState({
                            state: 'integration-pending',
                            timeout: 5000,
                          })
                        }
                        colorScheme="blue"
                      />
                    </Flex>
                    <Text fontWeight="bold">
                      Below is just an example how your dashboard may look like if you
                      will integrate your bank.
                    </Text>
                    <Divider />
                    <Box position="relative">
                      {isOpen && (
                        <Flex
                          zIndex={1}
                          h="30%"
                          w="100%"
                          position="absolute"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Card bg={bg}>
                            <Stack spacing={[2, 4, 8]}>
                              <Text fontWeight="bold">
                                Below is just an example how your dashboard may look like
                                if you will integrate your bank.
                              </Text>
                              <Button onClick={onClose}>Got it!</Button>
                            </Stack>
                          </Card>
                        </Flex>
                      )}
                      <Stack
                        spacing={[2, 4, 8]}
                        sx={{ filter: 'blur(8px)', transition: 'all 0.5s' }}
                        _hover={{ filter: 'none' }}
                      >
                        <Flex justifyContent="space-between">
                          <Heading as="h1" size="md">
                            Current balance
                          </Heading>
                          <Heading as="h2" size="sm">
                            Last updated: {utcDayJs().format('MMMM D, YYYY')}
                          </Heading>
                        </Flex>
                        <BalanceCards
                          balance={
                            JSON.parse(fakeDashboard).data.dashboard.history.balance
                          }
                          data={{
                            integrations: JSON.parse(fakeBalance).data.integrations,
                          }}
                        />
                        <Divider />
                        <Actuals dashboard={JSON.parse(fakeDashboard).data.dashboard} />
                      </Stack>
                    </Box>
                  </>
                )
              case 'DashboardReady':
                return (
                  <>
                    <Stack spacing={[2, 4, 8]}>
                      <Flex justifyContent="space-between">
                        <Heading as="h1" size="md">
                          Current balance
                        </Heading>
                        <Heading as="h2" size="sm">
                          Last updated:{' '}
                          {utcDayJs(dashboard.lastUpdated.value).format('MMMM D, YYYY')}
                        </Heading>
                      </Flex>
                      <Balances balance={dashboard.history.balance} />
                      <Divider />
                    </Stack>
                    <Actuals dashboard={dashboard} />
                  </>
                )
              case 'DashboardNoData':
                return (
                  <GenericEmpty>
                    Current bank region does not have integrated bank
                  </GenericEmpty>
                )
              default:
                return impossibleState(dashboard)
            }
          }
          default:
            return impossibleState(dashboardState)
        }
      })()}
    </Stack>
  )
}
