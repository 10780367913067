import { Flex, Heading, Text } from '@chakra-ui/react'
import { useCommon_MeUserQuery } from 'packlets/queries'
import { GenericError, GenericSpinner } from '@liveflow-io/component-common'
import { Navigate } from 'react-router-dom'
import { Routes } from 'packlets/constants'

export const NotEnabledPage = () => {
  const [, , meResult] = useCommon_MeUserQuery()

  if (meResult.fetching) {
    return <GenericSpinner />
  }

  if (meResult.error) {
    return <GenericError />
  }

  if (meResult.data?.enabled) {
    return <Navigate to={Routes.BANK_DASHBOARD} />
  }

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      w="100vw"
      h="100vh"
    >
      <Heading as="h1" size="xl">
        Access denied
      </Heading>
      <Text fontSize="2xl">
        If you wish to obtain access to our app, please ping us on{' '}
        <a href="mailto:support@liveflow.io">support@liveflow.io</a>
      </Text>
    </Flex>
  )
}
