import type { MotionProps } from 'framer-motion'
import { isValidMotionProp, motion } from 'framer-motion'
import React from 'react'
import type { ComponentWithAs, BoxProps, StackProps } from '@chakra-ui/react'
import { forwardRef, Box, Stack } from '@chakra-ui/react'

export type GenericMotionProps<Props> = Omit<Props, keyof MotionProps> &
  MotionProps & {
    as?: React.ElementType
  }

type As<Props = any> = React.ElementType<Props>

function generateMotion<Props extends object, T extends As>(
  Component: React.ComponentClass<Props, any> | React.FunctionComponent<Props>,
) {
  const Wrapped = motion.custom(
    forwardRef<Props, T>((props, ref) => {
      const chakraProps = Object.fromEntries(
        // do not pass framer props to DOM element
        Object.entries(props).filter(([key]) => !isValidMotionProp(key)),
      )
      // @ts-expect-error
      return <Component ref={ref} {...chakraProps} />
    }),
  ) as ComponentWithAs<T, GenericMotionProps<Props>>
  Wrapped.displayName = `Motion${Component.displayName}`
  return Wrapped
}

export const MotionBox = generateMotion<BoxProps, 'div'>(Box)
export type MotionBoxProps = GenericMotionProps<BoxProps>
export const MotionStack = generateMotion<StackProps, 'div'>(Stack)
export type StackBoxProps = GenericMotionProps<StackProps>
