// Required to initialize AuthService instance
// eslint-disable-next-line no-restricted-imports
import 'services/AuthService'

import 'dayjs/plugin/utc'
import 'dayjs/plugin/isBetween'
import 'dayjs/plugin/isSameOrAfter'
import 'dayjs/plugin/isSameOrBefore'

import { getEnvByHostname } from '@liveflow-io/utils-common'
import { hotjar } from 'react-hotjar'
import './mount'
import { reportWebVitals } from './reportWebVitals'

hotjar.initialize(getEnvByHostname() === 'production' ? 2196732 : 2199700, 6)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
