import type { Common_MeFragment } from '../queries'
import { useCommon_MeUserQuery } from '../queries'
import { useReducedMotion as useFramerReducedMotion } from 'framer-motion'

export const useMe = (): Common_MeFragment | undefined | null => {
  const [, , { data }] = useCommon_MeUserQuery()
  return data?.me
}

export const useReducedMotion = (): boolean => {
  const me = useMe()
  const isReduceMotionSystemSetting = useFramerReducedMotion()
  if (isReduceMotionSystemSetting) {
    return isReduceMotionSystemSetting
  }
  if (me) {
    return me.settings.isReducedMotion
  }
  return Boolean(isReduceMotionSystemSetting)
}
