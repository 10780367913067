import type { UrqlRefetchFn, UrqlStates } from './useUrqlEnchanceHook'
import { useUpdate } from 'react-use'
import { useEffect, useRef } from 'react'

/**
 * You should use this one in case you don't want state machine switch listeners to react on "fetching" state
 * in case you changing `variables` for query. By default URQL will go through whole `fetching: true/false` for
 * `variables` change no matter caching strategy which is not what we want sometimes. This one will use last
 * `variables` result until new meaningful result comes (error or data of any sort)
 * @param state
 * @param refetch
 */
export function useQueryCachedResultForDiffArgs<T>([state, refetch]: [
  UrqlStates<T>,
  UrqlRefetchFn<T>,
]): [UrqlStates<T>, UrqlRefetchFn<T>] {
  const update = useUpdate()
  const prevState = useRef(state)
  const isGotToDoneOnceRef = useRef(false)
  useEffect(() => {
    const haventGotToDone = !isGotToDoneOnceRef.current
    if (
      haventGotToDone &&
      state.state !== 'fetching' &&
      state.state !== 'idle' &&
      state.state !== 'stale' &&
      state.state !== 'partial-stale'
    ) {
      isGotToDoneOnceRef.current = true
      prevState.current = state
      update()
    } else if (
      isGotToDoneOnceRef.current &&
      state.state !== 'fetching' &&
      state.state !== 'stale'
    ) {
      prevState.current = state
      update()
    }
  }, [state, update])
  return isGotToDoneOnceRef.current ? [prevState.current, refetch] : [state, refetch]
}
