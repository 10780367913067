import React, { useCallback, useRef } from 'react'
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from '@chakra-ui/react'
import { useEnchancedMutation, useToast } from '@liveflow-io/hooks-common'
import { impossibleState, isNotEmptyOrNullish } from '@liveflow-io/utils-common'
import { CASH_FLOW_EVENTS, TrackingService } from 'packlets/utils'
import { CashflowPage_DeleteBudgetDocument } from './documents.generated'

export const DeleteBudgetModal = ({
  budgetToDeleteId,
  unsetBudgetToDeleteId,
}: {
  budgetToDeleteId?: string
  unsetBudgetToDeleteId: (id?: string) => void
}) => {
  const onClose = useCallback(() => unsetBudgetToDeleteId(undefined), [
    unsetBudgetToDeleteId,
  ])
  const cancelRef = useRef<HTMLButtonElement>(null)
  const isOpen = isNotEmptyOrNullish(budgetToDeleteId)
  const [, deleteBudget, rawData] = useEnchancedMutation(
    CashflowPage_DeleteBudgetDocument,
  )
  const isSubmitting = rawData.fetching
  const toast = useToast()
  const onSubmit = useCallback(
    (id: string) => {
      deleteBudget({
        id,
      })
        .then((result) => {
          switch (result.state) {
            case 'error':
            case 'partial':
              toast({
                title: 'Oh no! Something went wrong!',
                status: 'error',
                description: JSON.stringify(result.error, null, 2),
              })
              break
            case 'done': {
              TrackingService.track(CASH_FLOW_EVENTS.DELETED_BUDGET)
              toast({
                title: 'Success!',
                description: `Budget has been deleted.`,
              })
              onClose()

              break
            }
            default:
              impossibleState(result)
          }
          return result
        })
        .catch(console.error)
    },
    [deleteBudget, onClose, toast],
  )

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete budget
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You can&apos;t undo this action afterwards.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose} isLoading={isSubmitting}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={() => onSubmit(budgetToDeleteId!)}
              isLoading={isSubmitting}
              ml={3}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
