import type { Dayjs } from 'dayjs'
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'
import generatePicker from 'antd/es/date-picker/generatePicker'
import './antd-date-picker.css'
import './antd-tag.css'
import './antd.css'

export type {
  RangePickerProps as AntdRangePickerProps,
  PickerProps as AntdPickerProps,
} from 'antd/es/date-picker/generatePicker'

export const DatePickerBase = generatePicker<Dayjs>(dayjsGenerateConfig)
export const { RangePicker: RangePickerBase } = DatePickerBase
