import type { TypeOf } from 'zod'
import { object, string } from 'zod'
import type { CombinedError } from 'urql'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEnchancedMutation, useToast } from '@liveflow-io/hooks-common'
import React, { useCallback, useEffect, useState } from 'react'
import {
  degraphqlData,
  impossibleState,
  removeEmptyStringFields,
} from '@liveflow-io/utils-common'
import { ONBOARDING_EVENTS, TrackingService } from 'packlets/utils'
import { identity, pickBy } from 'lodash'
import { Card, LabeledInput } from '@liveflow-io/component-common'
import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  Image,
  Stack,
  Checkbox,
  Collapse,
  useDisclosure,
} from '@chakra-ui/react'
import liveflowLogo from 'static/liveflow-logo-big.png'
import type {
  Onboarding_CompanyInputValidationProblemFragment,
  Onboarding_CreateCompanyResultFragment,
} from './documents.generated'
import { Onboarding_CreateCompanyPayloadDocument } from './documents.generated'

const companyCreateSchema = object({
  name: string().nonempty(),
  country: string().nonempty(),
  streetLine1: string().optional(),
  streetLine2: string().optional(),
  postCode: string().optional(),
  city: string().optional(),
  state: string().optional(),
})

type CreateCompanyType = TypeOf<typeof companyCreateSchema>

type CompanyFormStates =
  | { state: 'waiting-for-input' }
  | {
      state: 'server-validation-problems'
      validationProblem: Onboarding_CompanyInputValidationProblemFragment
    }
  | {
      state: 'server-error'
      error: CombinedError
    }
  | { state: 'creating-company'; input: CreateCompanyType }
  | { state: 'company-created'; result: Onboarding_CreateCompanyResultFragment }

export const CompanyForm = () => {
  const { register, handleSubmit, errors, setError } = useForm<CreateCompanyType>({
    resolver: zodResolver(companyCreateSchema),
  })
  const [, createCompany] = useEnchancedMutation(Onboarding_CreateCompanyPayloadDocument)
  const toast = useToast()
  const { isOpen, onToggle } = useDisclosure()

  const [payload, setState] = useState<CompanyFormStates>(() => ({
    state: 'waiting-for-input',
  }))

  const onSubmit = useCallback((input: CreateCompanyType) => {
    setState({ state: 'creating-company', input: removeEmptyStringFields(input) })
  }, [])

  useEffect(() => {
    switch (payload.state) {
      case 'server-validation-problems':
        Object.entries(
          degraphqlData(pickBy(payload.validationProblem, identity)),
        ).forEach(([key, message]) => {
          setError(key as any, {
            message: message as string,
            shouldFocus: false,
          })
        })
        setState({ state: 'waiting-for-input' })
        break

      case 'creating-company':
        createCompany({ input: payload.input })
          .then((response) => {
            switch (response.state) {
              case 'done':
                {
                  const problem = response.data.onboardingCompanyCreate.problem
                  if (problem?.__typename === 'CompanyInputValidationProblem') {
                    setState({
                      state: 'server-validation-problems',
                      validationProblem: problem,
                    })
                  } else {
                    const result = response.data.onboardingCompanyCreate.result
                    if (result) {
                      setState({ state: 'company-created', result })
                    }
                  }
                }
                break
              case 'partial':
              case 'error':
                setState({
                  state: 'server-error',
                  error: response.error,
                })
                break
              default:
                impossibleState(response)
            }
            return response
          })
          .catch(console.error)
        break

      case 'server-error':
        toast({
          title: 'Oh no! Something went wrong!',
          status: 'error',
          description: JSON.stringify(payload.error),
        })
        setState({ state: 'waiting-for-input' })
        break

      case 'company-created':
        toast({
          title: 'Onboarded successfully!',
        })
        TrackingService.track(ONBOARDING_EVENTS.ONBOARDING_SUCCESS)
        break

      case 'waiting-for-input':
        break
      default:
        impossibleState(payload)
    }
  }, [createCompany, payload, setError, toast])

  const submitting =
    payload.state === 'creating-company' || payload.state === 'company-created'

  return (
    <Card as="form" w={['100%', '30em']} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <Center my={4}>
          <Image src={liveflowLogo} width={150} alt="Liveflow logo" />
        </Center>
        <Divider />
        <Heading as="h1" size="md">
          Well done! Next, please share your company information below.
        </Heading>
        <Box>
          <LabeledInput
            label="Company name*"
            placeholder="Awesome company"
            ref={register}
            name="name"
            errorLabel={errors.name?.message}
            isDisabled={submitting}
            isInvalid={!!errors.name}
          />
        </Box>
        <Box>
          <LabeledInput
            label="Country*"
            placeholder="Examplandia"
            errorLabel={errors.country?.message}
            ref={register}
            name="country"
            isDisabled={submitting}
            isInvalid={!!errors.country}
          />
        </Box>
        <Checkbox onChange={onToggle}>Show optional inputs</Checkbox>
        <Collapse in={isOpen} animateOpacity>
          <Box>
            <LabeledInput
              label="Street line 1"
              placeholder="24 Best Street"
              name="streetLine1"
              ref={register}
              errorLabel={errors.streetLine1?.message}
              isDisabled={submitting}
              isInvalid={!!errors.streetLine1}
            />
          </Box>
          <Box>
            <LabeledInput
              label="Street line 2"
              ref={register}
              name="streetLine2"
              errorLabel={errors.streetLine2?.message}
              isDisabled={submitting}
              isInvalid={!!errors.streetLine2}
            />
          </Box>
          <Box>
            <LabeledInput
              label="City"
              placeholder="Wonderful Hills"
              errorLabel={errors.city?.message}
              ref={register}
              name="city"
              isDisabled={submitting}
              isInvalid={!!errors.city}
            />
          </Box>
          <Box>
            <LabeledInput
              label="Postсode / ZipCode"
              placeholder="90210"
              errorLabel={errors.postCode?.message}
              ref={register}
              name="postCode"
              isDisabled={submitting}
              isInvalid={!!errors.postCode}
            />
          </Box>
          <Box>
            <LabeledInput
              label="State"
              placeholder="California"
              errorLabel={errors.state?.message}
              ref={register}
              name="state"
              isDisabled={submitting}
              isInvalid={!!errors.state}
            />
          </Box>
        </Collapse>
      </Stack>
      <Button
        mt={8}
        type="submit"
        colorScheme="blue"
        isLoading={submitting}
        loadingText="Saving..."
      >
        Save
      </Button>
    </Card>
  )
}
