import React from 'react'
import { useEnchancedMutation, useToast } from '@liveflow-io/hooks-common'
import { useMe } from 'packlets/hooks'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { degraphqlData, impossibleState } from '@liveflow-io/utils-common'
import { identity, pickBy } from 'lodash'
import type { TypeOf } from 'zod'
import { object, string } from 'zod'
import { SETTINGS_EVENTS, TrackingService } from 'packlets/utils'
import { Card, LabeledInput } from '@liveflow-io/component-common'
import { Box, Button, Stack } from '@chakra-ui/react'
import { CardHeading } from './Settings'
import { Settings_EditCompanyPayloadDocument } from './documents.generated'

const editCompanySchema = object({
  name: string().nonempty(),
  email: string().nonempty().email(),
  country: string().nonempty(),
  streetLine1: string().optional(),
  streetLine2: string().optional(),
  postCode: string().optional(),
  city: string().optional(),
  state: string().optional(),
})
type EditCompanyType = TypeOf<typeof editCompanySchema>
export const EditCompanyForm = () => {
  const toast = useToast()
  const me = useMe()
  const company = me?.company ?? {}
  const {
    register,
    handleSubmit,
    errors,
    setError,
    formState,
  } = useForm<EditCompanyType>({
    resolver: zodResolver(editCompanySchema),
    defaultValues: {
      ...company,
    },
  })
  const [createCompanyState, editCompany] = useEnchancedMutation(
    Settings_EditCompanyPayloadDocument,
  )
  const onSubmit = (input: EditCompanyType) => {
    editCompany({ input })
      .then((response) => {
        switch (response.state) {
          case 'done':
            {
              const problem = response.data.companyEdit.problem
              if (problem?.__typename === 'CompanyInputValidationProblem') {
                Object.entries(degraphqlData(pickBy(problem, identity))).forEach(
                  ([key, message]) => {
                    setError(key as any, {
                      message: message as string,
                      shouldFocus: false,
                    })
                  },
                )
              }
              const result = response.data.companyEdit.result
              if (result) {
                TrackingService.track(SETTINGS_EVENTS.COMPANY_INFO_CHANGED)
                toast({
                  title: 'Company info changed successfully!',
                })
              }
            }
            break
          case 'partial':
          case 'error': {
            toast({
              status: 'error',
              title: 'Something went terribly wrong!',
              description: JSON.stringify(response.error, null, 2),
            })
            break
          }
          default:
            impossibleState(response)
            break
        }
        return response
      })
      .catch(console.error)
  }
  const submitting = createCompanyState.state === 'fetching'

  return (
    <Card
      w={{ base: 'full', lg: 'lg' }}
      maxW="3xl"
      as="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing={6}>
        <CardHeading title="Company information">
          This information will be used to contact you and basic analytics
        </CardHeading>
        <Box>
          <LabeledInput
            isRequired
            label="Name"
            placeholder="Awesome company"
            ref={register}
            name="name"
            errorLabel={errors.name?.message}
            isDisabled={submitting}
            isInvalid={!!errors.name}
          />
        </Box>
        <Box>
          <LabeledInput
            isRequired
            label="E-mail"
            placeholder="ceo@awesome.shop"
            ref={register}
            name="email"
            errorLabel={errors.email?.message}
            isDisabled={submitting}
            isInvalid={!!errors.email}
          />
        </Box>
        <Box>
          <LabeledInput
            label="Street line 1"
            placeholder="24 Best Street"
            name="streetLine1"
            ref={register}
            isDisabled={submitting}
          />
        </Box>
        <Box>
          <LabeledInput
            label="Street line 2"
            ref={register}
            name="streetLine2"
            errorLabel={errors.streetLine2?.message}
            isDisabled={submitting}
          />
        </Box>
        <Box>
          <LabeledInput
            label="City"
            placeholder="Wonderful Hills"
            ref={register}
            name="city"
            isDisabled={submitting}
          />
        </Box>
        <Box>
          <LabeledInput
            label="Post code"
            placeholder="90210"
            ref={register}
            name="postCode"
            isDisabled={submitting}
          />
        </Box>
        <Box>
          <LabeledInput
            isRequired
            label="Country"
            placeholder="Examplandia"
            errorLabel={errors.country?.message}
            ref={register}
            name="country"
            isDisabled={submitting}
            isInvalid={!!errors.country}
          />
        </Box>
        <Box>
          <LabeledInput
            label="State"
            placeholder="California"
            ref={register}
            name="state"
            isDisabled={submitting}
          />
        </Box>
      </Stack>
      <Button
        w="full"
        mt={4}
        type="submit"
        colorScheme={
          Object.values(formState.touched).some((it) => it) ? 'blue' : undefined
        }
        isLoading={submitting}
        loadingText="Saving..."
      >
        Save
      </Button>
    </Card>
  )
}
