import type { UseDisclosureReturn } from '@chakra-ui/react'
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
} from '@chakra-ui/react'
import { useEnchancedMutation, useToast } from '@liveflow-io/hooks-common'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import React, { useCallback } from 'react'
import { impossibleState, utcDayJs } from '@liveflow-io/utils-common'
import { CASH_FLOW_EVENTS, TrackingService } from 'packlets/utils'
import type { BudgetType } from './Cashflow'
import { CashflowPage_EditBudgetDocument } from './documents.generated'

type EditBudgetFormType = {
  cashIn: number
  cashOut: number
}
export const EditBudgetModal = ({
  onClose,
  budget,
}: {
  onClose: UseDisclosureReturn['onClose']
  budget: BudgetType
}) => {
  const [, editBudget] = useEnchancedMutation(CashflowPage_EditBudgetDocument)
  const { register, handleSubmit } = useForm<EditBudgetFormType>({
    defaultValues: {
      cashOut: budget.expectedCashOut,
      cashIn: budget.expectedCashIn,
    },
  })
  const toast = useToast()
  const onSubmit = handleSubmit(
    useCallback<SubmitHandler<EditBudgetFormType>>(
      ({ cashIn, cashOut }) => {
        onClose()
        const date = utcDayJs(budget.startDate)
        editBudget({
          id: budget.id,
          input: {
            expectedCashOut: cashOut,
            expectedCashIn: cashIn,
            startDate: date.toGraphQLDate(),
            endDate: date.endOf('months').toGraphQLDate(),
          },
        })
          .then((result) => {
            switch (result.state) {
              case 'error':
              case 'partial':
                toast({
                  title: 'Oh no! Something went wrong!',
                  status: 'error',
                  description: JSON.stringify(result.error, null, 2),
                })
                break
              case 'done': {
                TrackingService.track(CASH_FLOW_EVENTS.EDITED_BUDGET)
                toast({
                  title: 'Success!',
                  description: `Your budget for ${date.format('MMMM')} has been saved.`,
                })
                break
              }
              default:
                impossibleState(result)
            }
            return result
          })
          .catch(console.error)
      },
      [budget.id, budget.startDate, editBudget, onClose, toast],
    ),
  )
  return (
    <Modal isOpen={Boolean(budget)} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={onSubmit}>
        <ModalHeader>Edit budget</ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Stack} spacing={[2, 4]}>
          <HStack spacing={[2, 4]}>
            <FormControl id="edit-budget-revenue" isRequired>
              <FormLabel>Cash in</FormLabel>
              <NumberInput min={0}>
                <NumberInputField
                  placeholder="12,000"
                  name="cashIn"
                  ref={register({
                    required: true,
                    valueAsNumber: true,
                  })}
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>

            <FormControl id="edit-budget-expenses" isRequired>
              <FormLabel>Cash out</FormLabel>
              <NumberInput min={0}>
                <NumberInputField
                  placeholder="5,000"
                  name="cashOut"
                  ref={register({
                    required: true,
                    valueAsNumber: true,
                  })}
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
          </HStack>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" type="submit">
            Save budget
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
