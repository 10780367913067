import type { UseDisclosureReturn } from '@chakra-ui/react'
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Tooltip,
  Stack,
} from '@chakra-ui/react'
import { impossibleState, isNotEmptyOrNullish } from '@liveflow-io/utils-common'
import { CASH_FLOW_EVENTS, TrackingService } from 'packlets/utils'
import { useEnchancedMutation, useToast } from '@liveflow-io/hooks-common'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import React, { useCallback } from 'react'
import type { TypeOf } from 'zod'
import { string, number, object } from 'zod'
import { upperFirst } from 'lodash'
import { zodResolver } from '@hookform/resolvers/zod'
import { CashflowPage_CreateBudgetDocument } from './documents.generated'
import { dayjs } from '@liveflow-io/utils-common'

const createBudgetFormType = object({
  cashIn: number().positive(),
  cashOut: number().positive(),
  date: string()
    .nonempty()
    .refine((it) => dayjs.parseYyyyMm(it).isValid(), {
      message: 'Should be date of format YYYY-MM',
    }),
})

type CreateBudgetFormType = TypeOf<typeof createBudgetFormType>

export const CreateBudgetModal = ({
  onClose,
  isOpen,
  minMonth,
}: {
  onClose: UseDisclosureReturn['onClose']
  isOpen: UseDisclosureReturn['isOpen']
  minMonth: dayjs.Dayjs
}) => {
  const [, createBudget, mutationRawData] = useEnchancedMutation(
    CashflowPage_CreateBudgetDocument,
  )
  const { register, handleSubmit, errors } = useForm<CreateBudgetFormType>({
    resolver: zodResolver(createBudgetFormType),
    defaultValues: {
      date: minMonth.formatYyyyMm(),
    },
  })
  const isSubmitting = mutationRawData.fetching
  const toast = useToast()
  const onSubmit = handleSubmit(
    useCallback<SubmitHandler<CreateBudgetFormType>>(
      ({ date: aDate, cashIn, cashOut }) => {
        const anDate = dayjs.parseYyyyMm(aDate)
        createBudget({
          input: {
            expectedCashOut: cashOut,
            expectedCashIn: cashIn,
            startDate: `${aDate}-01`,
            endDate: anDate.endOf('month').toGraphQLDate(),
          },
        })
          .then((result) => {
            switch (result.state) {
              case 'error':
              case 'partial':
                toast({
                  title: 'Oh no! Something went wrong!',
                  status: 'error',
                  description: JSON.stringify(result.error, null, 2),
                })
                break
              case 'done': {
                if (result.data.budgetCreate.result) {
                  TrackingService.track(CASH_FLOW_EVENTS.ADDED_BUDGET)
                  toast({
                    title: 'Success!',
                    description: `Your budget for ${anDate.format(
                      'MMMM',
                    )} has been saved.`,
                  })
                  onClose()
                } else {
                  toast({
                    title: 'Budget already exists.',
                    status: 'error',
                    description: result.data.budgetCreate?.problem?.message,
                  })
                }
                break
              }
              default:
                impossibleState(result)
            }
            return result
          })
          .catch(console.error)
      },
      [createBudget, onClose, toast],
    ),
  )
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={onSubmit}>
        <ModalHeader>Add budget</ModalHeader>
        {!isSubmitting && <ModalCloseButton />}
        <ModalBody as={Stack} spacing={[2, 4]}>
          <FormControl
            id="budget-month"
            isInvalid={isNotEmptyOrNullish(errors.date?.message)}
          >
            <FormLabel>Month</FormLabel>
            <Tooltip
              label={upperFirst(errors.date?.message)}
              bg="red.500"
              isOpen={!!errors.date}
              hasArrow
              placement="bottom"
            >
              <Input type="month" name="date" ref={register} placeholder="YYYY-MM" />
            </Tooltip>
          </FormControl>
          <HStack spacing={[2, 4]}>
            <FormControl
              id="budget-revenue"
              isInvalid={isNotEmptyOrNullish(errors.cashIn?.message)}
            >
              <FormLabel>Cash in</FormLabel>
              <NumberInput min={0}>
                <NumberInputField
                  placeholder="12,000"
                  name="cashIn"
                  ref={register({ valueAsNumber: true })}
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>

            <FormControl
              id="budget-expenses"
              isInvalid={isNotEmptyOrNullish(errors.cashOut?.message)}
            >
              <FormLabel>Cash out</FormLabel>
              <NumberInput min={0}>
                <NumberInputField
                  placeholder="5,000"
                  name="cashOut"
                  ref={register({ valueAsNumber: true })}
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
          </HStack>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" type="submit" isLoading={isSubmitting}>
            Save budget
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
