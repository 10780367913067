import { useEffect } from 'react'
import { CurrentUserService, TrackingService } from '../utils'
import { Common_MeUserDocument } from './MeQuery.generated'
import { useEnchancedQuery } from '@liveflow-io/hooks-common'

export type {
  Common_MeFragment,
  Common_MeUserQueryVariables,
  Common_MeUserQuery,
  Common_MeCompanyFragment,
} from './MeQuery.generated'

export const useCommon_MeUserQuery = () => {
  const query = useEnchancedQuery({
    query: Common_MeUserDocument,
  })
  const [, , state] = query
  useEffect(() => {
    if (state.data?.me) {
      CurrentUserService.setUserInfo(state.data.me)
      TrackingService.identify(state.data.me.id, state.data.me)
    }
  }, [state])

  return query
}
