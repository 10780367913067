import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { ChakraProvider, GlobalStyle } from '@chakra-ui/react'
import { BrowserRouter as Router } from 'react-router-dom'
import { UrqlProvider, AuthProvider } from 'providers'
import { Provider as JotaiProvider } from 'jotai'
import { App } from './pages'
import { customTheme } from './theme'

ReactDOM.render(
  <StrictMode>
    <ChakraProvider theme={customTheme}>
      <GlobalStyle />
      <AuthProvider>
        <Router>
          <UrqlProvider>
            <JotaiProvider>
              <App />
            </JotaiProvider>
          </UrqlProvider>
        </Router>
      </AuthProvider>
    </ChakraProvider>
  </StrictMode>,
  document.getElementById('root'),
)
