import type { Auth0Callback, Auth0Error, WebAuth } from 'auth0-js'
import auth0 from 'auth0-js'
import {
  isEmptyOrNullish,
  isNotEmptyOrNullish,
  isNullish,
} from '@liveflow-io/utils-common'

export const AUTH_CHANGE_EVENT = 'authChange'

const authEvent = new Event(AUTH_CHANGE_EVENT)

// DO NOT EXPORT THIS IS PRIVATE
class AuthService {
  private readonly auth0: WebAuth

  private idToken?: string

  private accessToken?: string

  private profile?: any

  private jwtPayload?: any

  private readonly signOut: string

  constructor(clientId: string, domain: string, audience: string, signOut: string) {
    this.auth0 = new auth0.WebAuth({
      domain,
      clientID: clientId,
      audience,
      redirectUri: `${window.location.origin}/authRedirect`,
      responseType: 'code token id_token',
      scope: 'openid email profile',
    })
    this.signOut = signOut
    this.idToken = localStorage.getItem('id_token') ?? undefined
    this.accessToken = localStorage.getItem('access_token') ?? undefined
    const profile = localStorage.getItem('profile')
    this.profile = !isNullish(profile) ? JSON.parse(profile) : undefined
    const jwtPayload = localStorage.getItem('jwt_payload')
    this.jwtPayload = !isNullish(jwtPayload) ? JSON.parse(jwtPayload) : undefined
    if (this.isTokenExpired()) {
      this.logout()
    }
  }

  private getJwtPayload = () => this.jwtPayload

  getProfile = () => this.profile

  getIdToken = () => this.idToken

  getAccessToken = () => this.accessToken

  getAuth0Client = () => this.auth0

  isLoggedIn = () => {
    // Checks if there is a saved token and it's still valid
    const token = this.getIdToken()
    return !isEmptyOrNullish(token)
  }

  private getTokenExpirationDate = () => {
    const decoded = this.getJwtPayload()
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!decoded?.exp) {
      return null
    }
    const date = new Date(0) // The 0 here is the key, which sets the date to the epoch
    date.setUTCSeconds(decoded.exp)
    return date
  }

  private setAccessToken = (accessToken: string) => {
    localStorage.setItem('access_token', accessToken)
    this.accessToken = accessToken
  }

  private setIdToken = (idToken: string) => {
    localStorage.setItem('id_token', idToken)
    this.idToken = idToken
  }

  private setJwtPayload = (idTokenPayload: string) => {
    localStorage.setItem('jwt_payload', idTokenPayload)
    this.jwtPayload = isNotEmptyOrNullish(idTokenPayload)
      ? JSON.parse(idTokenPayload)
      : undefined
  }

  private setProfile = (profile: any) => {
    // Saves profile data to localStorage
    localStorage.setItem('profile', JSON.stringify(profile))
    this.profile = profile
  }

  /*  login = ({
    email,
    password,
    onError,
  }: {
    email: string
    password: string
    onError?: (error: Auth0Error) => void
  }) => {
    this.auth0.login(
      {
        realm: 'Username-Password-Authentication',
        username: email,
        password,
      },
      (err) => {
        if (err) {
          console.error(`Error`, err)
          onError?.(err)
        }
      },
    )
  } */

  passwordless = ({
    email,
    onError,
  }: {
    email: string
    onError?: (error: Auth0Error) => void
  }) => {
    this.auth0.passwordlessStart(
      {
        send: 'link',
        connection: 'email',
        email,
      },
      (err) => {
        if (err) {
          console.error(`Error`, err)
          onError?.(err)
        }
      },
    )
  }

  /*  resetPassword = ({
    email,
    onSuccess,
    onError,
  }: {
    email: string
    onSuccess?: (result: any) => void
    onError?: (error: Auth0Error) => void
  }) => {
    this.auth0.changePassword(
      { email, connection: 'Username-Password-Authentication' },
      (error, result) => {
        if (error) {
          console.error(`Error:`, error)
          onError?.(error)
        }
        if (result) {
          onSuccess?.(result)
        }
      },
    )
  }

  signup = ({
    email,
    password,
    onSuccess,
    onError,
  }: {
    email: string
    password: string
    onSuccess?: (result: any) => void
    onError?: (error: Auth0Error) => void
  }) => {
    this.auth0.signup(
      {
        connection: 'Username-Password-Authentication',
        email,
        password,
      },
      (err, result) => {
        if (result) {
          onSuccess?.(result)
        }
        if (err) {
          console.error(`Error:`, err)
          onError?.(err)
        }
      },
    )
  } */

  loginWithGoogle = () => {
    this.auth0.authorize({
      connection: 'google-oauth2',
    })
  }

  loginWithGSuite = () => {
    this.auth0.authorize({
      connection: 'GSuite',
    })
  }

  parseHash = (
    options: auth0.ParseHashOptions,
    {
      onSuccess,
      onError,
    }: {
      onSuccess?: (result: Parameters<Auth0Callback<any>>[1]) => void
      onError?: (result: Parameters<Auth0Callback<any>>[0]) => void
    },
  ) => {
    this.auth0.parseHash(options, (err, authResult) => {
      if (!isNullish(authResult)) {
        if (
          !isEmptyOrNullish(authResult.accessToken) &&
          !isEmptyOrNullish(authResult.idToken)
        ) {
          this.setAccessToken(authResult.accessToken)
          this.setIdToken(authResult.idToken)
          this.setJwtPayload(JSON.stringify(authResult.idTokenPayload))
          this.auth0.client.userInfo(authResult.accessToken, (error, profile) => {
            if (error) {
              console.error('Error loading the Profile', error)
            } else {
              this.setProfile(profile)
            }
            document.dispatchEvent(authEvent)
          })
          onSuccess?.(authResult)
        } else if (err) {
          console.error(`Error while parsing auth hash:`, err)
          onError?.(err)
        }
      }
      document.dispatchEvent(authEvent)
    })
  }

  isTokenExpired = () => {
    const date = this.getTokenExpirationDate()
    if (date === null) {
      return false
    }
    return !(date.valueOf() > new Date().valueOf())
  }

  logout = () => {
    // Clear user token and profile data from localStorage
    localStorage.removeItem('id_token')
    localStorage.removeItem('access_token')
    localStorage.removeItem('profile')
    localStorage.removeItem('jwt_payload')
    this.idToken = undefined
    this.accessToken = undefined
    this.profile = undefined
    this.jwtPayload = undefined
    this.auth0.logout({ returnTo: `${window.location.origin}${this.signOut}` })
    document.dispatchEvent(authEvent)
  }
}

export { AuthService }
