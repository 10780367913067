import type { SwitchProps } from '@chakra-ui/react'
import { Box, Flex, Switch, FormControl } from '@chakra-ui/react'
import * as React from 'react'
import { forwardRef } from 'react'
import { isNotNullish } from '@liveflow-io/utils-common'

interface DescriptionProps extends SwitchProps {
  id: string
  title: string
  children?: React.ReactNode
}

export const SettingSwitch = forwardRef<HTMLInputElement, DescriptionProps>(
  ({ id, title, children, ...rest }, ref) => {
    return (
      <FormControl
        id={id}
        as={Flex}
        align="center"
        pos="relative"
        justify="space-between"
      >
        <Box flex="1">
          <Box as="h4" fontWeight="medium" maxW="xl">
            {title}
          </Box>
          {isNotNullish(children) && (
            <Box maxW="xl" color="gray.500" fontSize="sm">
              {children}
            </Box>
          )}
        </Box>
        <Switch size="lg" ref={ref} {...rest} />
      </FormControl>
    )
  },
)
