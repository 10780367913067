import React, { useMemo } from 'react'
import { AccountSwitcher } from './AccountSwitcher'
import {
  Box,
  Stack,
  Flex,
  Image,
  useColorMode,
  useToken,
  useColorModeValue,
} from '@chakra-ui/react'
import { NavItem } from './NavItem'
import { BiBarChart, BiCog, BiLink, BiTrendingUp, BiUserPlus } from 'react-icons/bi'
import { RiBankLine } from 'react-icons/ri'
import { NavGroup } from './NavGroup'
import bigLogo from 'static/liveflow-logo-big.png'
import type { Company } from 'packlets/generated'
import { FaMoon, FaSun } from 'react-icons/fa'
import { Routes } from 'packlets/constants'
import { Link as RouterLink } from 'react-router-dom'
import { MotionBox } from '@liveflow-io/component-common'
import { useReducedMotion } from 'packlets/hooks'

const ThemeToggleItem = () => {
  const { colorMode, toggleColorMode } = useColorMode()
  const isLight = colorMode === 'light'
  return (
    <NavItem
      onClick={toggleColorMode}
      icon={isLight ? <FaMoon /> : <FaSun />}
      label="Toggle theme"
    />
  )
}

export const Navigation = ({ company }: { company: Company }) => {
  const bg = useColorModeValue('gray.50', 'gray.900')
  const color = useColorModeValue('black', 'white')
  const [gray900, gray700] = useToken('colors', ['gray.400', 'gray.700'])
  const shadowColor = useColorModeValue(
    `${gray900} 0px 0px 5px`,
    `${gray700} 0px 0px 5px`,
  )
  const isMotionReduced = useReducedMotion()

  const animation = useMemo(
    () => ({
      mount: (i: number) => ({
        x: 0,
        opacity: 1,
        transition: {
          ease: 'easeOut',
          duration: isMotionReduced ? 0 : 0.4,
          delay: isMotionReduced ? 0 : i * 0.075,
        },
      }),
    }),
    [isMotionReduced],
  )

  const initial = useMemo(
    () => ({
      x: isMotionReduced ? 0 : -50,
    }),
    [isMotionReduced],
  )

  return (
    <Flex
      w={['full', 'full', '64']}
      bg={bg}
      color={color}
      fontSize="sm"
      position={['relative', 'relative', 'fixed']}
      h={['full', 'full', '100vh']}
      direction="column"
      px="4"
      py="4"
      boxShadow={shadowColor}
    >
      <Stack spacing="6">
        <Image src={bigLogo} maxWidth={150} alt="Liveflow logo" fit="cover" />
        <MotionBox
          initial={initial}
          animate="mount"
          variants={animation}
          custom={0}
          opacity={0}
        >
          <AccountSwitcher company={company} />
        </MotionBox>
      </Stack>
      <Stack spacing="8" flex="1" overflow="auto" pt="8">
        <Stack spacing="1">
          <MotionBox
            initial={initial}
            animate="mount"
            variants={animation}
            custom={1}
            opacity={0}
          >
            <NavItem
              as={RouterLink}
              to={Routes.BANK_DASHBOARD}
              icon={<RiBankLine />}
              label="Bank dashboard"
            />
          </MotionBox>
          {/* <NavItem icon={<BiCommentAdd />} label="Cash flow" /> */}
        </Stack>
        <NavGroup label="Profit and Loss">
          <MotionBox
            initial={initial}
            animate="mount"
            variants={animation}
            custom={2}
            opacity={0}
          >
            <NavItem
              as={RouterLink}
              to={Routes.PROFIT_LOSS}
              icon={<BiTrendingUp />}
              label="Per entity"
            />
          </MotionBox>
          <MotionBox
            initial={initial}
            animate="mount"
            variants={animation}
            custom={3}
            opacity={0}
          >
            <NavItem
              as={RouterLink}
              to={Routes.CONSOLIDATED_PROFIT_LOSS}
              icon={<BiBarChart />}
              label="Consolidated"
            />
          </MotionBox>
        </NavGroup>
      </Stack>
      <Box>
        <Stack spacing="1">
          <MotionBox
            initial={initial}
            animate="mount"
            variants={animation}
            custom={1}
            opacity={0}
          >
            <NavItem
              as={RouterLink}
              to={Routes.INVITE}
              icon={<BiUserPlus />}
              label="Invite teammates"
            />
          </MotionBox>
          <MotionBox
            initial={initial}
            animate="mount"
            variants={animation}
            custom={2}
            opacity={0}
          >
            <NavItem
              as={RouterLink}
              to={Routes.INTEGRATIONS}
              icon={<BiLink />}
              label="Integrations"
            />
          </MotionBox>
          <MotionBox
            initial={initial}
            animate="mount"
            variants={animation}
            custom={3}
            opacity={0}
          >
            <NavItem
              as={RouterLink}
              to={Routes.SETTINGS}
              icon={<BiCog />}
              label="Settings"
            />
          </MotionBox>
          <MotionBox
            initial={initial}
            animate="mount"
            variants={animation}
            custom={4}
            opacity={0}
          >
            <ThemeToggleItem />
          </MotionBox>
          {/* <NavItem */}
          {/*  to={Routes.DASHBOARD} */}
          {/*  subtle */}
          {/*  icon={<BiBuoy />} */}
          {/*  label="Help & Support" */}
          {/*  endElement={<Circle size="2" bg="blue.400" />} */}
          {/* /> */}
        </Stack>
      </Box>
    </Flex>
  )
}
