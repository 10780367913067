import React, { useCallback, useEffect, useMemo, useState } from 'react'
import type { IconProps } from '@chakra-ui/react'
import {
  HStack,
  Icon,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react'
import type { MotionBoxProps } from '@liveflow-io/component-common'
import {
  GenericEmpty,
  GenericError,
  GenericSpinner,
  MotionBox,
} from '@liveflow-io/component-common'
import { FaAngleDoubleUp, FaAngleRight } from 'react-icons/fa'
import {
  renderMoneyOrNA,
  formatNumber,
  impossibleState,
  renderMoney,
  utcDayJs,
} from '@liveflow-io/utils-common'
import { useEnchancedQuery } from '@liveflow-io/hooks-common'
import { useBoolean } from 'react-use'
import { PNL_EVENTS, TrackingService } from 'packlets/utils'
import type { CategoriesToValuesOrNullPlaceholders } from 'utils'
import { buildMultipleItemsKeysToCellsMapping } from 'utils'
import { MonthRangePicker } from 'components'
import type { Dayjs } from 'dayjs'
import { useReducedMotion } from 'packlets/hooks'
import type { ProfitLossTable_ProfitLossPayloadFragment } from './documents.generated'
import { ProfitLoss_ProfitLossTableDocument } from './documents.generated'

export const ProfitLossTable = ({
  integrationId,
  earliestAvailableMonth,
  mostRecentAvailableMonth,
}: {
  integrationId: string
  earliestAvailableMonth: string
  mostRecentAvailableMonth: string
}) => {
  const mostRecentMonth = utcDayJs(mostRecentAvailableMonth)
  const earliestMonth = utcDayJs(earliestAvailableMonth)
  const presets = useMemo(
    () => ({
      'Current quarter': [mostRecentMonth.subtract(2, 'month'), mostRecentMonth],
      'Current - 1 quarter': [
        mostRecentMonth.subtract(5, 'month'),
        mostRecentMonth.subtract(3, 'month'),
      ],
      'Current - 2 quarters': [
        mostRecentMonth.subtract(8, 'month'),
        mostRecentMonth.subtract(6, 'month'),
      ],
      'Current - 3 quarters': [
        mostRecentMonth.subtract(11, 'month'),
        mostRecentMonth.subtract(9, 'month'),
      ],
      'Last year': [mostRecentMonth.subtract(1, 'year'), mostRecentMonth],
      'Previous year': [
        mostRecentMonth.subtract(1, 'month').subtract(2, 'year'),
        mostRecentMonth.subtract(1, 'month').subtract(1, 'year'),
      ],
    }),
    [mostRecentMonth],
  )

  const filteredPresets = useMemo(
    () =>
      Object.entries(presets).reduce((acc, [key, [start, end]]) => {
        if (start.isSameOrAfter(earliestMonth)) {
          return { ...acc, [key]: [start, end] }
        }
        if (end.isBefore(earliestMonth)) {
          return acc
        }
        return { ...acc, [key]: [earliestMonth, end] }
      }, {}),
    [earliestMonth, presets],
  )

  const [customFirstStartDate, setCustomFirstStartDate] = useState(
    mostRecentMonth.subtract(1, 'month'),
  )
  const [customFirstEndDate, setCustomFirstEndDate] = useState(
    mostRecentMonth.subtract(1, 'month'),
  )
  const [customSecondStartDate, setCustomSecondStartDate] = useState(mostRecentMonth)
  const [customSecondEndDate, setCustomSecondEndDate] = useState(mostRecentMonth)

  const isNotBeforeRecentAndAfterEarliest = useCallback(
    (it: Dayjs) => {
      return !it.isBetween(earliestMonth, mostRecentMonth, null, '[]')
    },
    [earliestMonth, mostRecentMonth],
  )

  const threeMonthsBeforeRecent = mostRecentMonth.subtract(2, 'month')
  const fourMonthsBeforeRecent = mostRecentMonth.subtract(3, 'month')

  const [profitLossState] = useEnchancedQuery({
    query: ProfitLoss_ProfitLossTableDocument,
    variables: {
      inputFirst: {
        selectedCurrency: 'USD',
        integrationId,
        startDate: mostRecentMonth.subtract(3, 'month').toGraphQLDate(),
        endDate: mostRecentMonth.subtract(3, 'month').toGraphQLDate(),
      },
      inputSecond: {
        startDate: mostRecentMonth.subtract(2, 'month').toGraphQLDate(),
        endDate: mostRecentMonth.subtract(2, 'month').toGraphQLDate(),
        selectedCurrency: 'USD',
        integrationId,
      },
      inputThird: {
        selectedCurrency: 'USD',
        integrationId,
        startDate: customFirstStartDate.toGraphQLDate(),
        endDate: customFirstEndDate.toGraphQLDate(),
      },
      inputFourth: {
        selectedCurrency: 'USD',
        integrationId,
        startDate: customSecondStartDate.toGraphQLDate(),
        endDate: customSecondEndDate.toGraphQLDate(),
      },
    },
  })

  const [showIncome, toggleShowIncome] = useBoolean(false)
  const [showCostOfSales, toggleShowCostOfSales] = useBoolean(false)
  const [showOperatingCost, toggleShowOperatingCost] = useBoolean(false)
  const [showOtherIncome, toggleShowOtherIncome] = useBoolean(false)
  const [showOtherExpenses, toggleShowOtherExpenses] = useBoolean(false)
  const [uncollapseAll, toggleUncollapseAll] = useBoolean(true)

  const onUncollapseAll = useCallback(() => {
    toggleShowIncome(true)
    toggleShowCostOfSales(true)
    toggleShowOperatingCost(true)
    toggleShowOtherIncome(true)
    toggleShowOtherExpenses(true)
  }, [
    toggleShowIncome,
    toggleShowCostOfSales,
    toggleShowOperatingCost,
    toggleShowOtherIncome,
    toggleShowOtherExpenses,
  ])

  const onCollapseAll = useCallback(() => {
    toggleShowIncome(false)
    toggleShowCostOfSales(false)
    toggleShowOperatingCost(false)
    toggleShowOtherIncome(false)
    toggleShowOtherExpenses(false)
  }, [
    toggleShowIncome,
    toggleShowCostOfSales,
    toggleShowOperatingCost,
    toggleShowOtherIncome,
    toggleShowOtherExpenses,
  ])

  useEffect(() => {
    ;[
      showIncome,
      showCostOfSales,
      showOperatingCost,
      showOtherIncome,
      showOtherExpenses,
    ].some(Boolean)
      ? toggleUncollapseAll(false)
      : toggleUncollapseAll(true)
  }, [
    toggleUncollapseAll,
    showIncome,
    showCostOfSales,
    showOperatingCost,
    showOtherIncome,
    showOtherExpenses,
  ])

  const red = useColorModeValue('red.500', 'red.300')
  const green = useColorModeValue('green.500', 'green.300')
  const blue = useColorModeValue('blue.500', 'blue.300')
  const grayBg = useColorModeValue('gray.50', 'gray.700')

  const getColor = useCallback(
    (num: number) => {
      return num < 0 ? red : green
    },
    [red, green],
  )
  const isDone = profitLossState.state === 'done' && profitLossState.data
  const allProfitLosses = useMemo(() => {
    if (isDone !== false) {
      const { customFirst, customSecond, third, fourth } = isDone
      return [
        customFirst,
        customSecond,
        third,
        fourth,
        // @ts-expect-error
      ].filter<ProfitLossTable_ProfitLossPayloadFragment>(Boolean)
    }
    return null
  }, [isDone])

  const filledWithPlaceholdersCategoriesMapping = useMemo(() => {
    return buildMultipleItemsKeysToCellsMapping(allProfitLosses)
  }, [allProfitLosses])
  switch (profitLossState.state) {
    case 'idle':
    case 'fetching':
      return <GenericSpinner />
    case 'partial':
    case 'partial-stale':
    case 'error':
      return <GenericError />
    case 'stale':
    case 'done': {
      if (!filledWithPlaceholdersCategoriesMapping) {
        return <GenericEmpty />
      }
      if (!allProfitLosses) {
        return <GenericEmpty />
      }
      if (allProfitLosses.length === 0) {
        return (
          <GenericEmpty
            message={<Text>Data fetching is in progress, wait for it to appear.</Text>}
          />
        )
      }
      const {
        otherExpenses,
        costOfSales,
        income,
        operatingCost,
        otherIncome,
      } = filledWithPlaceholdersCategoriesMapping
      return (
        <Table w="100%" sx={{ borderCollapse: 'unset', borderSpacing: 0 }} size="sm">
          <Thead>
            <Tr bg={grayBg}>
              <Th>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <MotionBox
                    animate={{ rotate: uncollapseAll ? 0 : 180 }}
                    w="fit-content"
                  >
                    <Icon
                      cursor="pointer"
                      as={FaAngleDoubleUp}
                      onClick={uncollapseAll ? onUncollapseAll : onCollapseAll}
                      title={uncollapseAll ? 'Expand all' : 'Collapse all'}
                      h={4}
                      w={4}
                      color={blue}
                    />
                  </MotionBox>
                </Stack>
              </Th>
              <Th>{fourMonthsBeforeRecent.format('MMMM YYYY')}</Th>
              <Th>{threeMonthsBeforeRecent.format('MMMM YYYY')}</Th>
              <Th>
                <MonthRangePicker
                  ranges={filteredPresets}
                  allowClear={false}
                  disabledDate={isNotBeforeRecentAndAfterEarliest}
                  value={[customFirstStartDate, customFirstEndDate]}
                  // @ts-expect-error
                  onChange={([startDate, endDate]: [Dayjs, Dayjs]) => {
                    TrackingService.track(PNL_EVENTS.DATE_FILTER_CHANGED)
                    setCustomFirstStartDate(startDate)
                    setCustomFirstEndDate(endDate)
                  }}
                />
              </Th>
              <Th>
                <MonthRangePicker
                  ranges={filteredPresets}
                  allowClear={false}
                  disabledDate={isNotBeforeRecentAndAfterEarliest}
                  value={[customSecondStartDate, customSecondEndDate]}
                  // @ts-expect-error
                  onChange={([startDate, endDate]) => {
                    TrackingService.track(PNL_EVENTS.DATE_FILTER_CHANGED)
                    setCustomSecondStartDate(startDate)
                    setCustomSecondEndDate(endDate)
                  }}
                />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr onClick={toggleShowIncome}>
              <Td>
                <HStack>
                  <CollapseArrow open={showIncome} />
                  <Text fontWeight="bold">Income</Text>
                </HStack>
              </Td>
              {allProfitLosses.map((pnl, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Td fontWeight="bold" key={index}>
                  {renderMoney(pnl.incomeSum)}
                </Td>
              ))}
            </Tr>
            <AnimatedRows isOpen={showIncome} categoriesToValues={income} color={green} />

            <Tr onClick={toggleShowCostOfSales}>
              <Td>
                <HStack>
                  <CollapseArrow open={showCostOfSales} />
                  <Text fontWeight="bold">Cost of Sales</Text>
                </HStack>
              </Td>
              {allProfitLosses.map(({ costOfSalesSum }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Td fontWeight="bold" key={index} color={red}>
                  {renderMoney(costOfSalesSum)}
                </Td>
              ))}
            </Tr>
            <AnimatedRows
              isOpen={showCostOfSales}
              categoriesToValues={costOfSales}
              color={red}
            />

            <Tr bg={grayBg}>
              <Td>Gross Profit</Td>
              {allProfitLosses.map(({ grossProfit }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Td key={index} color={getColor(grossProfit.amount)}>
                  {renderMoney(grossProfit)}
                </Td>
              ))}
            </Tr>

            <Tr>
              <Td>Gross Profit Margin</Td>
              {allProfitLosses.map(({ grossMargin }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Td key={index} color={getColor(grossMargin ?? 0)}>
                  {grossMargin != null
                    ? formatNumber(grossMargin, {
                        style: 'percent',
                        minimumFractionDigits: 2,
                      })
                    : 'N/A'}
                </Td>
              ))}
            </Tr>

            <Tr onClick={toggleShowOperatingCost}>
              <Td>
                <HStack>
                  <CollapseArrow open={showOperatingCost} />
                  <Text fontWeight="bold">Operating costs</Text>
                </HStack>
              </Td>
              {allProfitLosses.map(({ operatingCostSum }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Td key={index} fontWeight="bold" color={red}>
                  {renderMoney(operatingCostSum)}
                </Td>
              ))}
            </Tr>
            <AnimatedRows
              isOpen={showOperatingCost}
              categoriesToValues={operatingCost}
              color={red}
            />

            <Tr bg={grayBg}>
              <Td>Operating Profit</Td>
              {allProfitLosses.map(({ operatingProfit }, index) => (
                <Td
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  fontWeight="bold"
                  color={getColor(operatingProfit.amount)}
                >
                  {renderMoney(operatingProfit)}
                </Td>
              ))}
            </Tr>

            <Tr>
              <Td>Operating Profit Margin</Td>
              {allProfitLosses.map(({ operatingProfitMargin }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Td key={index} color={getColor(operatingProfitMargin ?? 0)}>
                  {operatingProfitMargin != null
                    ? formatNumber(operatingProfitMargin, {
                        style: 'percent',
                        minimumFractionDigits: 2,
                      })
                    : 'N/A'}
                </Td>
              ))}
            </Tr>

            <Tr onClick={toggleShowOtherIncome}>
              <Td>
                <HStack>
                  <CollapseArrow open={showOtherIncome} />
                  <Text fontWeight="bold">Other Income</Text>
                </HStack>
              </Td>
              {allProfitLosses.map(({ otherIncomeSum }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Td key={index} fontWeight="bold">
                  {renderMoney(otherIncomeSum)}
                </Td>
              ))}
            </Tr>
            <AnimatedRows isOpen={showOtherIncome} categoriesToValues={otherIncome} />

            <Tr onClick={toggleShowOtherExpenses}>
              <Td>
                <HStack>
                  <CollapseArrow open={showOtherExpenses} />
                  <Text fontWeight="bold">Other Expenses</Text>
                </HStack>
              </Td>
              {allProfitLosses.map(({ otherExpensesSum }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Td key={index} fontWeight="bold">
                  {renderMoney(otherExpensesSum)}
                </Td>
              ))}
            </Tr>
            <AnimatedRows isOpen={showOtherExpenses} categoriesToValues={otherExpenses} />

            <Tr>
              <Td>Net Other Income</Td>
              {allProfitLosses.map(({ netOtherIncome }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Td key={index} fontWeight="bold" color={getColor(netOtherIncome.amount)}>
                  {renderMoney(netOtherIncome)}
                </Td>
              ))}
            </Tr>

            <Tr bg={grayBg}>
              <Td>Net Profit</Td>
              {allProfitLosses.map(({ netProfit }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Td key={index} fontWeight="bold" color={getColor(netProfit.amount)}>
                  {renderMoney(netProfit)}
                </Td>
              ))}
            </Tr>
            <Tr>
              <Td>Net Profit Margin</Td>
              {allProfitLosses.map(({ netProfitMargin }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Td key={index} color={getColor(netProfitMargin ?? 0)}>
                  {netProfitMargin != null
                    ? formatNumber(netProfitMargin, {
                        style: 'percent',
                        minimumFractionDigits: 2,
                      })
                    : 'N/A'}
                </Td>
              ))}
            </Tr>
          </Tbody>
        </Table>
      )
    }
    default:
      return impossibleState(profitLossState)
  }
}

const AnimatedRows = ({
  categoriesToValues,
  isOpen,
  color,
}: {
  categoriesToValues: CategoriesToValuesOrNullPlaceholders
  isOpen: boolean
  color?: string
}) => {
  return (
    <>
      {Object.entries(categoriesToValues).map(([category, values], index) => (
        <Tr
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        >
          <AnimatedCell isOpen={isOpen} index={index}>
            <PaddedAnimatedCellContent>{category}</PaddedAnimatedCellContent>
          </AnimatedCell>
          {values.map((value, anIndex) => (
            <AnimatedCell
              // eslint-disable-next-line react/no-array-index-key
              key={anIndex}
              color={color}
              isOpen={isOpen}
              index={index}
            >
              <AnimatedCellContent>{renderMoneyOrNA(value)}</AnimatedCellContent>
            </AnimatedCell>
          ))}
        </Tr>
      ))}
    </>
  )
}

const CollapseArrow = ({
  open,
  iconProps,
  ...props
}: MotionBoxProps & { open: boolean; iconProps?: IconProps }) => {
  const blue = useColorModeValue('blue.500', 'blue.300')
  return (
    <MotionBox animate={{ rotate: open ? 90 : 0 }} {...props}>
      <Icon as={FaAngleRight} color={blue} h={4} cursor="pointer" {...iconProps} />
    </MotionBox>
  )
}

const PaddedAnimatedCellContent = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text py={2} pl={10} pr={4}>
      {children}
    </Text>
  )
}

const AnimatedCellContent = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text py={2} px={4}>
      {children}
    </Text>
  )
}

/**
 * Please DO NO EXTEND THIS ONE. If you need to change, change, but do not try to build
 * on top of this one anything except something for this feature. Copy paste and fine tune to your own use case
 */
const AnimatedCell = ({
  index,
  isOpen,
  color,
  children,
}: {
  index: number
  isOpen: boolean
  color?: string
  children: React.ReactNode
}) => {
  const isMotionReduced = useReducedMotion()
  const pnlAnimationVariants = useMemo(
    () => ({
      open: (i: number) => ({
        height: 'auto',
        opacity: 1,
        x: 0,
        transition: {
          height: { duration: isMotionReduced ? 0 : 0.3 },
          opacity: {
            duration: isMotionReduced ? 0 : 0.1,
            delay: isMotionReduced ? 0 : (i + 1) * 0.075,
          },
          x: {
            ease: 'easeOut',
            duration: isMotionReduced ? 0 : 0.2,
            delay: isMotionReduced ? 0 : (i + 1) * 0.075,
          },
        },
      }),
      closed: {
        height: 0,
        opacity: 0,
        x: -50,
        transition: {
          height: { ease: 'easeOut', duration: isMotionReduced ? 0 : 0.4 },
          opacity: {
            duration: isMotionReduced ? 0 : 0.2,
          },
          x: {
            duration: isMotionReduced ? 0 : 0.5,
          },
        },
      },
    }),
    [isMotionReduced],
  )

  return (
    <Td p={0} borderBottomWidth={0} color={color}>
      <MotionBox
        overflow="hidden"
        h={0}
        opacity={0}
        custom={index}
        animate={isOpen ? 'open' : 'closed'}
        variants={pnlAnimationVariants}
      >
        {children}
      </MotionBox>
    </Td>
  )
}
