import { extendTheme } from '@chakra-ui/react'

const mode = (lightMode: string, darkMode: string) => (props: { colorMode: string }) =>
  props.colorMode === 'dark' ? darkMode : lightMode

export const customTheme = extendTheme({
  styles: {
    global: (props) => ({
      // ANT DATE PICKERS ///////////////////////////
      '.ant-picker': {
        color: mode('gray.800', 'whiteAlpha.900')(props),
        bg: 'inherit',
        borderColor: mode('gray.200', 'gray.600')(props),
      },
      '.ant-picker-input > input::placeholder': {
        color: mode('gray.400', 'gray.600')(props),
      },
      '.ant-picker-panel-focused': { borderColor: mode('blue.200', 'blue.700')(props) },
      '.ant-picker-input > input': {
        color: mode('gray.800', 'whiteAlpha.900')(props),
      },
      '.ant-picker:hover, .ant-picker-focused': {
        borderColor: mode('gray.300', 'gray.500')(props),
      },
      '.ant-picker-suffix': {
        bg: 'inherit',
        color: mode('gray.800', 'whiteAlpha.900')(props),
      },
      '.ant-picker-clear': {
        bg: mode('white', 'gray.800')(props),
        color: mode('gray.800', 'whiteAlpha.900')(props),
      },
      // STRIPE INPUT ///////////////////////////
      '.StripeElement': {
        display: 'block',
        maxWidth: '500px',
        padding: '10px 14px',
        fontSize: '1em',
        fontFamily: '"Source Code Pro", monospace',
        boxShadow:
          'rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px',
        border: '0',
        outline: '0',
        borderRadius: '4px',
        background: mode('white', 'gray.800')(props),
      },
      '.StripeElement--focus': {
        boxShadow:
          'rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px',
        transition: 'all 150ms ease',
      },
      '.StripeElement.IdealBankElement, .StripeElement.FpxBankElement,.StripeElement.PaymentRequestButton': {
        padding: '0',
      },
      '.StripeElement.PaymentRequestButton': { height: '40px' },
    }),
  },
  colors: {
    black: '#333333',
  },
  config: {
    useSystemColorMode: false,
    initialColorMode: window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light',
  },
})
