import type { Common_MeFragment } from '../queries'
import { isNotEmptyOrNullish } from '@liveflow-io/utils-common'

const USER_INFO_KEY = '__!userInfo'

window.addEventListener('beforeunload', () => {
  localStorage.removeItem(USER_INFO_KEY)
})

export const CurrentUserService = {
  getUserinfo(): Common_MeFragment | undefined {
    const userInfo = localStorage.get(USER_INFO_KEY)
    return isNotEmptyOrNullish(userInfo) ? JSON.parse(userInfo) : undefined
  },
  setUserInfo(data: any) {
    return localStorage.setItem(USER_INFO_KEY, JSON.stringify(data))
  },
}
