import { useEnchancedQuery } from '@liveflow-io/hooks-common'
import { Heading, Stack, Text, useColorModeValue, useTheme } from '@chakra-ui/react'
import {
  Card,
  GenericEmpty,
  GenericError,
  GenericSpinner,
} from '@liveflow-io/component-common'
import { formatMoney, impossibleState } from '@liveflow-io/utils-common'
import React from 'react'
import { useAtomValue } from 'jotai/utils'
import { DashboardGraph } from './DashboardGraph'
import type { Dashboard_MetricHistoryPointFragment } from './documents.generated'
import { filterChangeResultsFetchingAtom, integrationSourceAtom } from './BankDashboard'
import { get } from 'lodash'
import type { CommonIntegrations_IntegrationPayloadQuery } from 'gql/queries/IntegrationsQuery.generated'
import { CommonIntegrations_IntegrationPayloadDocument } from 'gql/queries/IntegrationsQuery.generated'

export const Balances = ({
  balance,
}: {
  balance: Dashboard_MetricHistoryPointFragment[]
}) => {
  const [integrationsMachine] = useEnchancedQuery({
    query: CommonIntegrations_IntegrationPayloadDocument,
  })
  const isFilterChangeResultsFetching = useAtomValue(filterChangeResultsFetchingAtom)

  if (isFilterChangeResultsFetching) {
    return <GenericSpinner containerProps={{ h: '300px' }} />
  }

  switch (integrationsMachine.state) {
    case 'partial':
    case 'partial-stale':
    case 'error':
      return <GenericError />
    case 'fetching':
    case 'idle':
      return <GenericSpinner />
    case 'stale':
    case 'done': {
      return <BalanceCards balance={balance} data={integrationsMachine.data} />
    }
    default:
      return impossibleState(integrationsMachine)
  }
}

export const BalanceCards = ({
  data,
  balance,
}: {
  data: CommonIntegrations_IntegrationPayloadQuery
  balance: Dashboard_MetricHistoryPointFragment[]
}) => {
  const revenueColor = useColorModeValue('green.500', 'green.300')
  const { colors } = useTheme()
  const blueColor = get(colors, useColorModeValue('blue.500', 'blue.300'))

  const isFilterChangeResultsFetching = useAtomValue(filterChangeResultsFetchingAtom)
  const integrationSource = useAtomValue(integrationSourceAtom)

  if (isFilterChangeResultsFetching) {
    return <GenericSpinner />
  }
  const { integrations } = data
  return (
    <Stack direction={{ base: 'column', lg: 'row' }} spacing={[2, 4, 8]}>
      {integrations.length !== 0 ? (
        integrations
          .filter((it) => it.integrationSource === integrationSource)
          .map((it) => {
            return (
              <Card key={it.integrationId} width="100%" p={8}>
                {it.__typename === 'CommonBankIntegrationPayload' ? (
                  <>
                    <Heading
                      as="h1"
                      size="lg"
                      fontWeight="normal"
                      sx={{
                        textDecoration: 'underline',
                        textUnderlineOffset: '16px',
                        textDecorationColor: blueColor,
                      }}
                    >
                      {it.bank}
                    </Heading>
                    <Heading as="h2" size="lg" color={revenueColor} mt={6}>
                      {formatMoney(it.balance ?? 0)}
                    </Heading>
                    <Text fontSize="xs" color="gray.400">
                      Note: Plaid synchronizes your bank data every 6 hours.
                    </Text>
                    {balance.length > 0 ? (
                      <DashboardGraph data={balance} />
                    ) : (
                      <GenericEmpty h="300px" />
                    )}
                  </>
                ) : null}
              </Card>
            )
          })
      ) : (
        <Text>No installed integrations</Text>
      )}
    </Stack>
  )
}
