import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Grid, SlideFade, Text } from '@chakra-ui/react'
import {
  FixedThemeToggleButton,
  GenericError,
  GenericSpinner,
} from '@liveflow-io/component-common'
import { FaDoorOpen } from 'react-icons/fa'
import { impossibleState } from '@liveflow-io/utils-common'
import { ONBOARDING_EVENTS, TrackingService } from 'packlets/utils'
import { Navigate } from 'react-router-dom'
import type { Common_MeCompanyFragment, Common_MeFragment } from 'packlets/queries'
import { Routes } from 'packlets/constants'
import { AuthService } from 'services/AuthService'
import { CompanyForm } from './CompanyForm'
import { UserForm } from './UserForm'
import { useCommon_MeUserQuery } from 'packlets/queries'

export const OnboardingPage = () => {
  const [meQuery] = useCommon_MeUserQuery()
  switch (meQuery.state) {
    case 'error':
    case 'partial':
    case 'partial-stale':
      return <GenericError />
    case 'idle':
    case 'fetching':
      return <GenericSpinner />
    case 'done':
    case 'stale': {
      const me = meQuery.data.me
      if (me?.isOnboarded) {
        return <Navigate to={Routes.BANK_DASHBOARD} />
      }
      return <Onboarding me={meQuery.data.me as Common_MeFragment} />
    }
    default:
      return impossibleState(meQuery)
  }
}

type OnboardingStates =
  | { state: 'user-step' }
  | { state: 'company-step'; user: Common_MeFragment }
  | { state: 'user-onboarded'; company: Common_MeCompanyFragment }

const Onboarding = ({ me }: { me?: Common_MeFragment }) => {
  useEffect(() => {
    TrackingService.track(ONBOARDING_EVENTS.ONBOARDING_STARTED)
  }, [])

  const calculateCurrentState = useCallback((): OnboardingStates => {
    switch (me) {
      case undefined:
      case null:
        return { state: 'user-step' }
      default: {
        switch (me.company) {
          case undefined:
          case null:
            return { state: 'company-step', user: me }
          default:
            return {
              company: me.company,
              state: 'user-onboarded',
            }
        }
      }
    }
  }, [me])

  const [onboardingState, setOnbardingState] = useState<OnboardingStates>(
    calculateCurrentState,
  )

  useEffect(() => {
    setOnbardingState(calculateCurrentState())
  }, [calculateCurrentState])

  switch (onboardingState.state) {
    case 'user-onboarded':
      return null
    case 'user-step':
    case 'company-step':
      return (
        <Grid w="100vw" h="100vh" alignItems="center" justifyItems="center">
          <FixedThemeToggleButton />
          <Box position="fixed" right={4} top={4}>
            <Button pl={2} pr={2} onClick={AuthService.logout} fontSize="lg">
              <FaDoorOpen /> <Text ml={2}>Logout</Text>
            </Button>
          </Box>
          <SlideFade in>
            {onboardingState.state === 'company-step' ? <CompanyForm /> : <UserForm />}
          </SlideFade>
        </Grid>
      )
    default:
      return impossibleState(onboardingState)
  }
}
