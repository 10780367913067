import type { MutableRefObject } from 'react'
import React, { useCallback, useEffect, useRef } from 'react'
import type { TypeOf } from 'zod'
import { object, string } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Collapse,
  Button,
  Center,
  Divider,
  Grid,
  FormControl,
  Stack,
  Image,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Input,
  PopoverBody,
  PopoverArrow,
  PopoverHeader,
  useBreakpointValue,
  Tooltip,
} from '@chakra-ui/react'
import { FaGoogle, FaLink } from 'react-icons/fa'
import { useBoolean } from 'react-use'
import { upperFirst } from 'lodash'
import { SIGN_IN_EVENTS, TrackingService } from 'packlets/utils'
import { Card, FixedThemeToggleButton } from '@liveflow-io/component-common'
import { useToast } from '@liveflow-io/hooks-common'
import { useIsLoggedIn } from 'providers'
import { Navigate } from 'react-router-dom'
import liveflowLogo from 'static/liveflow-logo-big.png'
import { Routes } from 'packlets/constants'
import { AuthService } from 'services/AuthService'
import { isNotEmptyOrNullish } from '@liveflow-io/utils-common'

const loginSchema = object({
  email: string().email(),
})

type LoginFormType = TypeOf<typeof loginSchema>

export const SignIn = () => {
  const { register, handleSubmit, errors } = useForm<LoginFormType>({
    resolver: zodResolver(loginSchema),
  })
  const [openPopover, setPopover] = useBoolean(false)
  const tooltipPlacement = useBreakpointValue(['top', 'right'])
  const toast = useToast()
  const isLoggedIn = useIsLoggedIn()
  const [submitting, setSubmitting] = useBoolean(false)
  const [magicLinkSelected, setMagicLinkSelected] = useBoolean(false)
  const onSubmit = useCallback(
    (data: LoginFormType) => {
      setSubmitting(true)
      setPopover(true)
      toast({
        title: "We've sent you an e-mail!",
        description: 'Please, check your e-mail for the magic link!',
      })
      TrackingService.track(SIGN_IN_EVENTS.BY_MAGIC_LINK_ATTEMPT)
      AuthService.passwordless({
        ...data,
        onError: (error) => {
          TrackingService.track(SIGN_IN_EVENTS.BY_MAGIC_LINK_ERROR, error)
          setSubmitting(false)

          toast({
            status: 'error',
            description: error?.description,
            title: 'Oh no!',
          })
        },
      })
    },
    [setPopover, toast, setSubmitting],
  )

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const code = urlParams.get('inviteCode')
    isNotEmptyOrNullish(code) && localStorage.setItem('inviteCode', code)
  }, [])

  const emailInputRef: MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement>(
    null,
  )

  return (
    <Grid w="100%" h="100%" minHeight="inherit" alignItems="center" justifyItems="center">
      {isLoggedIn && <Navigate to={Routes.BANK_DASHBOARD} />}
      <FixedThemeToggleButton />
      <Card as="form" w={['100%', '30em']} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <Center my={4}>
            <Image src={liveflowLogo} width={150} alt="Liveflow logo" />
          </Center>
          <Divider />
        </Stack>
        <Stack spacing={1} mt={8} justifyContent="space-between">
          <Popover isOpen={openPopover} placement="top">
            <PopoverTrigger>
              <Button
                type={magicLinkSelected ? 'submit' : 'button'}
                onClick={
                  !magicLinkSelected
                    ? (e) => {
                        e.preventDefault()
                        setMagicLinkSelected(true)
                        setTimeout(() => {
                          emailInputRef.current?.focus()
                        }, 0)
                      }
                    : undefined
                }
                colorScheme="blue"
                isLoading={submitting}
                leftIcon={<FaLink />}
                loadingText="Check your email..."
                isFullWidth
              >
                Sign-in using magic link
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverHeader fontWeight="bold">Great!</PopoverHeader>
              <PopoverBody>
                Please make sure to open the magic link we e-mailed to you in the same
                browser.
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <Collapse in={magicLinkSelected} animateOpacity style={{ overflow: 'visible' }}>
            <Tooltip
              label={upperFirst(errors.email?.message)}
              bg="red.500"
              isOpen={!!errors.email}
              hasArrow
              placement={tooltipPlacement as 'right'}
            >
              <FormControl id="email" isInvalid={!!errors.email}>
                <Input
                  isDisabled={submitting}
                  name="email"
                  ref={(e) => {
                    register(e)
                    emailInputRef.current = e
                  }}
                  placeholder="Provide e-mail to send it"
                />
              </FormControl>
            </Tooltip>
          </Collapse>
          <HStack alignItems="center">
            <Divider />
            <Center>or</Center>
            <Divider />
          </HStack>
          <Button
            type="button"
            colorScheme="red"
            disabled={submitting}
            leftIcon={<FaGoogle />}
            onClick={() => {
              TrackingService.track(SIGN_IN_EVENTS.GOOGLE_AUTH_ATTEMPT)
              AuthService.loginWithGoogle()
            }}
            isFullWidth
          >
            Sign-in using Google
          </Button>
        </Stack>
      </Card>
    </Grid>
  )
}
