import React, { useCallback, useEffect, useMemo } from 'react'
import type { IconProps } from '@chakra-ui/react'
import {
  Icon,
  Td,
  Text,
  Tr,
  Thead,
  Table,
  Tbody,
  useColorModeValue,
  Stack,
  Th,
  HStack,
  Button,
} from '@chakra-ui/react'
import {
  renderMoneyOrNA,
  formatNumber,
  impossibleState,
  renderMoney,
} from '@liveflow-io/utils-common'
import type { MotionBoxProps } from '@liveflow-io/component-common'
import {
  GenericEmpty,
  GenericError,
  GenericSpinner,
  MotionBox,
} from '@liveflow-io/component-common'
import { FaAngleDoubleUp, FaAngleRight } from 'react-icons/fa'
import { useBoolean } from 'react-use'
import {
  useEnchancedMutation,
  useEnchancedQuery,
  useToast,
} from '@liveflow-io/hooks-common'
import type { CategoriesToValuesOrNullPlaceholders } from 'utils'
import { buildMultipleItemsKeysToCellsMapping } from 'utils'
import { CONSOLIDATED_PNL_EVENTS, TrackingService } from 'packlets/utils'
import { useReducedMotion } from 'packlets/hooks'
import {
  ConsolidateProfitLoss_RemoveEntityDocument,
  ConsolidatedProfitLoss_ConsolidatedProfitLossTableDocument,
} from './documents.generated'

export const ConsolidatedProfitLossTable = ({
  selectedMonth,
  selectedCurrency,
  integrationIdToNameMapping,
}: {
  integrationIdToNameMapping: Record<string, string>
  selectedMonth: string
  selectedCurrency: string
}) => {
  const [, removeEntity] = useEnchancedMutation(
    ConsolidateProfitLoss_RemoveEntityDocument,
  )
  const toast = useToast()

  const onRemoveEntity = async (integrationId: string) => {
    const response = await removeEntity({ integrationId })
    switch (response.state) {
      case 'error':
      case 'partial':
        toast({
          status: 'error',
          title: 'Oh no, something went wrong!',
          description:
            'Unfortunately something unexpected has happened, please, refresh the page..',
        })
        break
      case 'done':
        TrackingService.track(CONSOLIDATED_PNL_EVENTS.REMOVED_ENTITY)
        toast({
          title: 'Success',
          description: 'Entity has been removed from PnL!',
        })
        break
      default:
        impossibleState(response)
    }
  }

  const [consolidatedProfitLossMachine, , consolidatedProfitLoss] = useEnchancedQuery({
    query: ConsolidatedProfitLoss_ConsolidatedProfitLossTableDocument,
    variables: {
      input: {
        month: selectedMonth,
        selectedCurrency,
      },
    },
  })

  const [showIncome, toggleShowIncome] = useBoolean(false)
  const [showCostOfSales, toggleShowCostOfSales] = useBoolean(false)
  const [showOperatingCost, toggleShowOperatingCost] = useBoolean(false)
  const [showOtherIncome, toggleShowOtherIncome] = useBoolean(false)
  const [showOtherExpenses, toggleShowOtherExpenses] = useBoolean(false)
  const [uncollapseAll, toggleUncollapseAll] = useBoolean(true)

  const onUncollapseAll = useCallback(() => {
    toggleShowIncome(true)
    toggleShowCostOfSales(true)
    toggleShowOperatingCost(true)
    toggleShowOtherIncome(true)
    toggleShowOtherExpenses(true)
  }, [
    toggleShowIncome,
    toggleShowCostOfSales,
    toggleShowOperatingCost,
    toggleShowOtherIncome,
    toggleShowOtherExpenses,
  ])

  const onCollapseAll = useCallback(() => {
    toggleShowIncome(false)
    toggleShowCostOfSales(false)
    toggleShowOperatingCost(false)
    toggleShowOtherIncome(false)
    toggleShowOtherExpenses(false)
  }, [
    toggleShowIncome,
    toggleShowCostOfSales,
    toggleShowOperatingCost,
    toggleShowOtherIncome,
    toggleShowOtherExpenses,
  ])

  useEffect(() => {
    ;[
      showIncome,
      showCostOfSales,
      showOperatingCost,
      showOtherIncome,
      showOtherExpenses,
    ].some(Boolean)
      ? toggleUncollapseAll(false)
      : toggleUncollapseAll(true)
  }, [
    toggleUncollapseAll,
    showIncome,
    showCostOfSales,
    showOperatingCost,
    showOtherIncome,
    showOtherExpenses,
  ])

  const red = useColorModeValue('red.500', 'red.300')
  const green = useColorModeValue('green.500', 'green.300')
  const blue = useColorModeValue('blue.500', 'blue.300')
  const grayBg = useColorModeValue('gray.50', 'gray.700')

  const getColor = useCallback(
    (num: number) => {
      return num < 0 ? red : green
    },
    [red, green],
  )

  const profitLossConsolidated = useMemo(() => {
    return consolidatedProfitLoss.data?.profitLossConsolidated
  }, [consolidatedProfitLoss.data?.profitLossConsolidated])

  const allProfitLosses = useMemo(() => {
    return profitLossConsolidated
      ? profitLossConsolidated.entities.concat(
          // @ts-expect-error
          profitLossConsolidated.consolidatedProfitAndLoss,
        )
      : []
  }, [profitLossConsolidated])

  const filledWithPlaceholdersCategoriesMapping = useMemo(() => {
    return buildMultipleItemsKeysToCellsMapping(allProfitLosses)
  }, [allProfitLosses])
  switch (consolidatedProfitLossMachine.state) {
    case 'idle':
    case 'fetching':
      return <GenericSpinner />
    case 'partial':
    case 'partial-stale':
    case 'error':
      return <GenericError />
    case 'stale':
    case 'done': {
      if (!filledWithPlaceholdersCategoriesMapping || allProfitLosses.length === 0) {
        return <GenericEmpty />
      }
      const {
        otherExpenses,
        costOfSales,
        income,
        operatingCost,
        otherIncome,
      } = filledWithPlaceholdersCategoriesMapping

      return (
        <Table size="sm" sx={{ borderCollapse: 'unset', borderSpacing: 0 }} w="100%">
          <Thead>
            <Tr bg={grayBg}>
              <Th>
                <MotionBox animate={{ rotate: uncollapseAll ? 0 : 180 }} w="fit-content">
                  <Icon
                    cursor="pointer"
                    as={FaAngleDoubleUp}
                    onClick={uncollapseAll ? onUncollapseAll : onCollapseAll}
                    title={uncollapseAll ? 'Expand all' : 'Collapse all'}
                    h={4}
                    color={blue}
                  />
                </MotionBox>
              </Th>
              {allProfitLosses.map((it, index) => {
                return it.__typename === 'ProfitLoss' ? (
                  // eslint-disable-next-line react/no-array-index-key
                  <Th key={index}>
                    <Stack direction="row" alignItems="center">
                      <Text>{integrationIdToNameMapping[it.integrationId]}</Text>
                      <Button size="xs" onClick={() => onRemoveEntity(it.integrationId)}>
                        Remove
                      </Button>
                    </Stack>
                  </Th>
                ) : (
                  // eslint-disable-next-line react/no-array-index-key
                  <Th key={index}>Consolidated PnL</Th>
                )
              })}
            </Tr>
          </Thead>
          <Tbody>
            <Tr onClick={toggleShowIncome}>
              <Td>
                <HStack>
                  <CollapseArrow open={showIncome} />
                  <Text fontWeight="bold">Income</Text>
                </HStack>
              </Td>
              {allProfitLosses.map((pnl, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Td fontWeight="bold" key={index}>
                  {renderMoney(pnl.incomeSum)}
                </Td>
              ))}
            </Tr>
            <AnimatedRows isOpen={showIncome} categoriesToValues={income} color={green} />

            <Tr onClick={toggleShowCostOfSales}>
              <Td>
                <HStack>
                  <CollapseArrow open={showCostOfSales} />
                  <Text fontWeight="bold">Cost of Sales</Text>
                </HStack>
              </Td>
              {allProfitLosses.map(({ costOfSalesSum }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Td fontWeight="bold" key={index} color={red}>
                  {renderMoney(costOfSalesSum)}
                </Td>
              ))}
            </Tr>
            <AnimatedRows
              isOpen={showCostOfSales}
              categoriesToValues={costOfSales}
              color={red}
            />

            <Tr bg={grayBg}>
              <Td>Gross Profit</Td>
              {allProfitLosses.map(({ grossProfit }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Td key={index} color={getColor(grossProfit.amount)}>
                  {renderMoney(grossProfit)}
                </Td>
              ))}
            </Tr>

            <Tr>
              <Td>Gross Profit Margin</Td>
              {allProfitLosses.map(({ grossMargin }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Td key={index} color={getColor(grossMargin ?? 0)}>
                  {grossMargin != null
                    ? formatNumber(grossMargin, {
                        style: 'percent',
                        minimumFractionDigits: 2,
                      })
                    : 'N/A'}
                </Td>
              ))}
            </Tr>

            <Tr onClick={toggleShowOperatingCost}>
              <Td>
                <HStack>
                  <CollapseArrow open={showOperatingCost} />
                  <Text fontWeight="bold">Operating costs</Text>
                </HStack>
              </Td>
              {allProfitLosses.map(({ operatingCostSum }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Td key={index} fontWeight="bold" color={red}>
                  {renderMoney(operatingCostSum)}
                </Td>
              ))}
            </Tr>
            <AnimatedRows
              isOpen={showOperatingCost}
              categoriesToValues={operatingCost}
              color={red}
            />

            <Tr bg={grayBg}>
              <Td>Operating Profit</Td>
              {allProfitLosses.map(({ operatingProfit }, index) => (
                <Td
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  fontWeight="bold"
                  color={getColor(operatingProfit.amount)}
                >
                  {renderMoney(operatingProfit)}
                </Td>
              ))}
            </Tr>

            <Tr>
              <Td>Operating Profit Margin</Td>
              {allProfitLosses.map(({ operatingProfitMargin }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Td key={index} color={getColor(operatingProfitMargin ?? 0)}>
                  {operatingProfitMargin != null
                    ? formatNumber(operatingProfitMargin, {
                        style: 'percent',
                        minimumFractionDigits: 2,
                      })
                    : 'N/A'}
                </Td>
              ))}
            </Tr>

            <Tr onClick={toggleShowOtherIncome}>
              <Td>
                <HStack>
                  <CollapseArrow open={showOtherIncome} />
                  <Text fontWeight="bold">Other Income</Text>
                </HStack>
              </Td>
              {allProfitLosses.map(({ otherIncomeSum }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Td key={index} fontWeight="bold">
                  {renderMoney(otherIncomeSum)}
                </Td>
              ))}
            </Tr>
            <AnimatedRows isOpen={showOtherIncome} categoriesToValues={otherIncome} />

            <Tr onClick={toggleShowOtherExpenses}>
              <Td>
                <HStack>
                  <CollapseArrow open={showOtherExpenses} />
                  <Text fontWeight="bold">Other Expenses</Text>
                </HStack>
              </Td>
              {allProfitLosses.map(({ otherExpensesSum }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Td key={index} fontWeight="bold">
                  {renderMoney(otherExpensesSum)}
                </Td>
              ))}
            </Tr>
            <AnimatedRows isOpen={showOtherExpenses} categoriesToValues={otherExpenses} />

            <Tr>
              <Td>Net Other Income</Td>
              {allProfitLosses.map(({ netOtherIncome }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Td key={index} fontWeight="bold" color={getColor(netOtherIncome.amount)}>
                  {renderMoney(netOtherIncome)}
                </Td>
              ))}
            </Tr>

            <Tr bg={grayBg}>
              <Td>Net Profit</Td>
              {allProfitLosses.map(({ netProfit }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Td key={index} fontWeight="bold" color={getColor(netProfit.amount)}>
                  {renderMoney(netProfit)}
                </Td>
              ))}
            </Tr>
            <Tr>
              <Td>Net Profit Margin</Td>
              {allProfitLosses.map(({ netProfitMargin }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Td key={index} color={getColor(netProfitMargin ?? 0)}>
                  {netProfitMargin != null
                    ? formatNumber(netProfitMargin, {
                        style: 'percent',
                        minimumFractionDigits: 2,
                      })
                    : 'N/A'}
                </Td>
              ))}
            </Tr>
          </Tbody>
        </Table>
      )
    }
    default:
      return impossibleState(consolidatedProfitLossMachine)
  }
}

const AnimatedRows = ({
  categoriesToValues,
  isOpen,
  color,
}: {
  categoriesToValues: CategoriesToValuesOrNullPlaceholders
  isOpen: boolean
  color?: string
}) => {
  return (
    <>
      {Object.entries(categoriesToValues).map(([category, values], index) => (
        <Tr
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        >
          <AnimatedCell isOpen={isOpen} index={index}>
            <PaddedAnimatedCellContent>{category}</PaddedAnimatedCellContent>
          </AnimatedCell>
          {values.map((value, anIndex) => (
            <AnimatedCell
              // eslint-disable-next-line react/no-array-index-key
              key={anIndex}
              color={color}
              isOpen={isOpen}
              index={index}
            >
              <AnimatedCellContent>{renderMoneyOrNA(value)}</AnimatedCellContent>
            </AnimatedCell>
          ))}
        </Tr>
      ))}
    </>
  )
}

const CollapseArrow = ({
  open,
  iconProps,
  ...props
}: MotionBoxProps & { open: boolean; iconProps?: IconProps }) => {
  const blue = useColorModeValue('blue.500', 'blue.300')
  return (
    <MotionBox animate={{ rotate: open ? 90 : 0 }} {...props}>
      <Icon as={FaAngleRight} color={blue} h={4} cursor="pointer" {...iconProps} />
    </MotionBox>
  )
}

const PaddedAnimatedCellContent = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text py={2} pl={10} pr={4}>
      {children}
    </Text>
  )
}

const AnimatedCellContent = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text py={2} px={4}>
      {children}
    </Text>
  )
}

/**
 * Please DO NO EXTEND THIS ONE. If you need to change, change, but do not try to build
 * on top of this one anything except something for this feature. Copy paste and fine tune to your own use case
 */
const AnimatedCell = ({
  index,
  isOpen,
  color,
  children,
}: {
  index: number
  isOpen: boolean
  color?: string
  children: React.ReactNode
}) => {
  const isMotionReduced = useReducedMotion()
  const pnlAnimationVariants = useMemo(
    () => ({
      open: (i: number) => ({
        height: 'auto',
        opacity: 1,
        x: 0,
        transition: {
          height: { duration: isMotionReduced ? 0 : 0.3 },
          opacity: {
            duration: isMotionReduced ? 0 : 0.1,
            delay: isMotionReduced ? 0 : (i + 1) * 0.075,
          },
          x: {
            ease: 'easeOut',
            duration: isMotionReduced ? 0 : 0.2,
            delay: isMotionReduced ? 0 : (i + 1) * 0.075,
          },
        },
      }),
      closed: {
        height: 0,
        opacity: 0,
        x: isMotionReduced ? 0 : -50,
        transition: {
          height: { ease: 'easeOut', duration: isMotionReduced ? 0 : 0.4 },
          opacity: {
            duration: isMotionReduced ? 0 : 0.2,
          },
          x: {
            duration: isMotionReduced ? 0 : 0.5,
          },
        },
      },
    }),
    [isMotionReduced],
  )
  return (
    <Td p={0} borderBottomWidth={0} color={color}>
      <MotionBox
        overflow="hidden"
        h={0}
        opacity={0}
        custom={index}
        animate={isOpen ? 'open' : 'closed'}
        variants={pnlAnimationVariants}
      >
        {children}
      </MotionBox>
    </Td>
  )
}
