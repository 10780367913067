import type { Dashboard_DashboardReadyFragment } from './documents.generated'
import { useAtomValue } from 'jotai/utils'
import { Grid, Heading, Tooltip, useColorModeValue, useTheme } from '@chakra-ui/react'
import { Card, GenericEmpty, GenericSpinner } from '@liveflow-io/component-common'
import { InfoOutlineIcon } from '@chakra-ui/icons'
import { formatMoney, formatNumber } from '@liveflow-io/utils-common'
import { DashboardGraph } from './DashboardGraph'
import { BurnrateBarChart } from './BurnrateBarChart'
import { RunwayGraph } from './RunwayGraph'
import React from 'react'
import {
  amountOfMonthsSelectedAtom,
  filterChangeResultsFetchingAtom,
} from './BankDashboard'
import { get } from 'lodash'

export function Actuals({
  dashboard: {
    actuals,
    history: { burnRate, cashIn, cashOut, runway },
  },
}: {
  dashboard: Dashboard_DashboardReadyFragment
}) {
  const isFilterChangeResultsFetching = useAtomValue(filterChangeResultsFetchingAtom)
  const amountOfMonthsSelected = useAtomValue(amountOfMonthsSelectedAtom)
  const revenueColor = useColorModeValue('green.500', 'green.300')
  const expensesColor = useColorModeValue('red.500', 'red.300')
  const { colors } = useTheme()
  const blueColor = get(colors, useColorModeValue('blue.500', 'blue.300'))
  if (isFilterChangeResultsFetching) {
    return <GenericSpinner containerProps={{ h: '650px' }} />
  }
  return (
    <Grid
      gap={[2, 4, 8]}
      templateRows={{ base: '1fr', lg: 'repeat(2, 1fr)' }}
      templateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)' }}
    >
      <Card p={8}>
        <Heading
          as="h1"
          size="lg"
          fontWeight="normal"
          sx={{
            textDecoration: 'underline',
            textUnderlineOffset: '16px',
            textDecorationColor: blueColor,
          }}
        >
          Cash in{' '}
          <Tooltip
            hasArrow
            label="Note that if you transferred money to your other bank it's considered as
            Cash Out. If you'll add other bank account in the future we will see this
            and ignore such transactions, for now it will be Cash Out and Cash In"
            p={2}
          >
            <InfoOutlineIcon />
          </Tooltip>
        </Heading>
        <Heading as="h2" size="lg" color={revenueColor} mt={6}>
          {formatMoney(actuals?.cashIn?.value?.amount ?? 0)}
        </Heading>
        {cashIn.length > 0 ? (
          <DashboardGraph data={cashIn} />
        ) : (
          <GenericEmpty h="300px" />
        )}
      </Card>
      <Card p={8}>
        <Heading
          as="h1"
          size="lg"
          fontWeight="normal"
          sx={{
            textDecoration: 'underline',
            textUnderlineOffset: '16px',
            textDecorationColor: blueColor,
          }}
        >
          Cash out{' '}
          <Tooltip
            hasArrow
            label="Note that if you transferred money to your other bank it's considered as
            Cash Out. If you'll add other bank account in the future we will see this
            and ignore such transactions, for now it will be Cash Out and Cash In"
            p={2}
          >
            <InfoOutlineIcon />
          </Tooltip>
        </Heading>

        <Heading size="lg" color={expensesColor} mt={6}>
          {formatMoney(actuals?.cashOut?.value?.amount ?? 0)}
        </Heading>
        {cashOut.length > 0 ? (
          <DashboardGraph data={cashOut} />
        ) : (
          <GenericEmpty h="300px" />
        )}
      </Card>
      <Card p={8}>
        <Heading
          as="h1"
          size="lg"
          fontWeight="normal"
          sx={{
            textDecoration: 'underline',
            textUnderlineOffset: '16px',
            textDecorationColor: blueColor,
          }}
        >
          {`Avg. burn rate for selected ${amountOfMonthsSelected}
            ${amountOfMonthsSelected === 1 ? 'month' : 'months'} `}
          <Tooltip
            hasArrow
            label="We calculate your Burn Rate using the gross burn rate method. This means that we define your burn rate as a sum of your monthly expenses. It’s also possible to calculate your net burn rate, which is your revenue minus expenses. We will add that feature soon. Ping us here if you have questions or improvement suggestions: support@liveflow.io."
            p={2}
          >
            <InfoOutlineIcon />
          </Tooltip>
        </Heading>
        <Heading as="h2" size="lg" color={expensesColor} mt={6}>
          {formatMoney(actuals?.burnRate?.value?.amount ?? 0)}
        </Heading>
        {burnRate.length > 0 ? (
          <BurnrateBarChart data={burnRate} />
        ) : (
          <GenericEmpty h="300px" />
        )}
      </Card>
      <Card p={8}>
        <Heading
          as="h1"
          size="lg"
          fontWeight="normal"
          sx={{
            textDecoration: 'underline',
            textUnderlineOffset: '16px',
            textDecorationColor: blueColor,
          }}
        >
          Runway{' '}
          <Tooltip
            hasArrow
            label="We calculate your runway by dividing your current cash balance with your average burn-rate over the last 3 months. Ping us at support@liveflow.io if you have any questions or suggestions on how we can improve."
            p={2}
          >
            <InfoOutlineIcon />
          </Tooltip>
        </Heading>
        <Heading as="h2" size="lg" mt={6}>
          {formatNumber(actuals?.runway?.value?.amount ?? 0)} Months
        </Heading>
        {runway.length > 0 ? <RunwayGraph data={runway} /> : <GenericEmpty h="300px" />}
      </Card>
    </Grid>
  )
}
