import React, { useMemo } from 'react'
import { retryExchange } from '@urql/exchange-retry'
import { createClient, dedupExchange, errorExchange, fetchExchange, Provider } from 'urql'
import { devtoolsExchange } from '@urql/devtools'
import { refocusExchange } from '@urql/exchange-refocus'
import type { Cache } from '@urql/exchange-graphcache'
import { cacheExchange } from '@urql/exchange-graphcache'
import type { IntrospectionQuery } from 'graphql'
import type {
  CreateBudgetPayload,
  CreateCompanyPayload,
  CreateUserPayload,
  ProfitLossConsolidatedAddEntityPayload,
  ProfitLossConsolidatedRemoveEntityPayload,
  Query,
} from 'packlets/generated'
import { introspection } from 'packlets/generated'
import { AuthService } from 'services'
import { useAccessToken } from 'providers'
import { isNotEmptyOrNullish } from '@liveflow-io/utils-common'

const dropAllQueriesWithField = (field: keyof Query, cache: Cache) => {
  const queries = cache.inspectFields('Query').filter((x) => x.fieldName === field)
  queries.forEach(({ fieldName, arguments: variables }) => {
    cache.invalidate('Query', fieldName, variables ?? undefined)
  })
}

export const UrqlProvider: React.FC = ({ children }) => {
  const token = useAccessToken()
  const client = useMemo(() => {
    const options: Parameters<typeof retryExchange>[0] = {
      initialDelayMs: 1000,
      maxDelayMs: 15000,
      randomDelay: true,
      maxNumberAttempts: 2,
      retryIf: (err) => !!err.networkError,
    }

    return createClient({
      url: '/graphql',
      exchanges: [
        devtoolsExchange,
        dedupExchange,
        refocusExchange(),
        cacheExchange({
          schema: (introspection as unknown) as IntrospectionQuery,
          keys: {
            PlaidIntegrationPayload: (data) => data.__typename + data?.integrationId,
            CommonIntegrationPayload: (data) => data.__typename + data?.integrationId,
            CommonBankIntegrationPayload: (data) => data.__typename + data?.integrationId,
            CommonAccountingIntegrationPayload: (data) =>
              data.__typename + data?.integrationId,
            DashboardNoIntegrations: () => null,
            ProfitLossDataAvailability: () => null,
            ConsolidatedProfitLossEntity: () => null,
            ConsolidatedProfitLossPayload: () => null,
            ProfitLoss: () => null,
            ConsolidatedProfitLoss: () => null,
            DashboardReady: () => null,
            ActualsReady: () => null,
            HistoryReady: () => null,
            LastUpdated: () => null,
            CashIn: () => null,
            CashOut: () => null,
            Runway: () => null,
            BurnRate: () => null,
            MetricHistoryPoint: () => null,
            ProfitLossItem: () => null,
            Money: () => null,
          },
          updates: {
            Mutation: {
              onboardingCompanyCreate: (data, args, cache) => {
                const onboardingCompanyCreate = data.onboardingCompanyCreate as CreateCompanyPayload | null
                if (onboardingCompanyCreate?.result) {
                  dropAllQueriesWithField('me', cache)
                }
              },
              onboardingUserCreate: (data, args, cache) => {
                const onboardingUserCreate = data.onboardingUserCreate as CreateUserPayload | null
                if (onboardingUserCreate?.result) {
                  dropAllQueriesWithField('me', cache)
                }
              },
              budgetCreate: (data, args, cache) => {
                const budgetCreate = data.budgetCreate as CreateBudgetPayload | null
                if (budgetCreate?.result) {
                  dropAllQueriesWithField('budget', cache)
                }
              },
              profitLossConsolidatedAddEntity: (data, args, cache) => {
                const profitLossConsolidatedAddEntity = data.profitLossConsolidatedAddEntity as ProfitLossConsolidatedAddEntityPayload | null
                if (
                  isNotEmptyOrNullish(
                    profitLossConsolidatedAddEntity?.message as string | undefined,
                  )
                ) {
                  dropAllQueriesWithField('profitLossConsolidated', cache)
                  dropAllQueriesWithField('profitLossConsolidatedDataAvailability', cache)
                  dropAllQueriesWithField('profitLossConsolidatedAddedEntities', cache)
                }
              },
              profitLossConsolidatedRemoveEntity: (data, args, cache) => {
                const profitLossConsolidatedRemoveEntity = data.profitLossConsolidatedRemoveEntity as ProfitLossConsolidatedRemoveEntityPayload | null
                if (
                  isNotEmptyOrNullish(
                    profitLossConsolidatedRemoveEntity?.message as string | undefined,
                  )
                ) {
                  dropAllQueriesWithField('profitLossConsolidated', cache)
                  dropAllQueriesWithField('profitLossConsolidatedDataAvailability', cache)
                  dropAllQueriesWithField('profitLossConsolidatedAddedEntities', cache)
                }
              },
              budgetDelete: (data, args, cache) => {
                const budgetDelete = data.budgetDelete as string | null
                if (isNotEmptyOrNullish(budgetDelete)) {
                  dropAllQueriesWithField('budget', cache)
                }
              },
            },
          },
        }),
        retryExchange(options),
        errorExchange({
          onError: (error) => {
            if (error.response?.status === 401) {
              AuthService.logout()
            }
          },
        }),
        fetchExchange,
      ],
      fetchOptions: () => {
        return {
          headers: { authorization: isNotEmptyOrNullish(token) ? `Bearer ${token}` : '' },
        }
      },
    })
  }, [token])

  return <Provider value={client}>{children}</Provider>
}
