import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useEnchancedQuery } from '@liveflow-io/hooks-common'
import { ProfitLossAvaliblity_ProfitLossPageDocument } from './documents.generated'
import { GenericEmpty, GenericError, GenericSpinner } from '@liveflow-io/component-common'
import {
  impossibleState,
  isEmptyOrNullish,
  isNotEmptyOrNullish,
  utcDayJs,
} from '@liveflow-io/utils-common'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { INTEGRATION_EVENTS, PNL_EVENTS, TrackingService } from 'packlets/utils'
import type { CommonIntegrations_CommonAccountingIntegrationPayloadFragment } from 'gql/queries/IntegrationsQuery.generated'
import { CommonIntegrations_IntegrationPayloadDocument } from 'gql/queries/IntegrationsQuery.generated'
import { ProfitLossTable } from './ProfitLossTable'
import { IntegrationSource, IntegrationStatus } from 'packlets/generated'
import { v4 } from 'uuid'
import { useQuery } from 'urql'

type CodatIntegrationPair = { name: string; integrationId: string }

const stubUuid = v4()

export const ProfitLossPage = () => {
  const [integrations] = useQuery({
    query: CommonIntegrations_IntegrationPayloadDocument,
  })
  const [codatIntegrationsPairs, setCodatIntegrations] = useState<CodatIntegrationPair[]>(
    [],
  )
  const [selectedIntegrationId, setSelectedIntegrationId] = useState<string>()
  const [profitLossAvailability] = useEnchancedQuery({
    query: ProfitLossAvaliblity_ProfitLossPageDocument,
    variables: {
      input: {
        integrationId: selectedIntegrationId ?? stubUuid,
      },
    },
  })

  const codatIntegrations = useMemo(() => {
    return integrations.data?.integrations.filter(
      (it): it is CommonIntegrations_CommonAccountingIntegrationPayloadFragment =>
        it.integrationSource === IntegrationSource.Codat,
    )
  }, [integrations.data?.integrations])

  const hasAtLeastOneIntegration = useMemo(
    () => codatIntegrations?.find((it) => it.status === IntegrationStatus.Success),
    [codatIntegrations],
  )

  const integrationsInProgress = useMemo(
    () => codatIntegrations?.filter((it) => it.status === IntegrationStatus.InProgress),
    [codatIntegrations],
  )

  useEffect(() => {
    if (isNotEmptyOrNullish(codatIntegrations)) {
      const theCodatIntegrationPairs = codatIntegrations.map<CodatIntegrationPair>(
        (it) => ({
          name: it.name ?? '',
          integrationId: it?.integrationId ?? '',
        }),
      )
      setCodatIntegrations(theCodatIntegrationPairs)
      setSelectedIntegrationId(theCodatIntegrationPairs[0].integrationId)
    }
  }, [codatIntegrations])

  const ProfitLossPageDataBoundary = useMemo(
    () => () => {
      switch (profitLossAvailability.state) {
        case 'idle':
        case 'fetching':
          return <GenericSpinner />
        case 'partial':
        case 'partial-stale':
        case 'error':
          return <GenericError />
        case 'stale':
        case 'done': {
          const { profitLossDataAvailability } = profitLossAvailability.data
          if (!profitLossDataAvailability || isEmptyOrNullish(selectedIntegrationId)) {
            return <GenericEmpty />
          }
          return (
            <ProfitLossTable
              integrationId={selectedIntegrationId}
              earliestAvailableMonth={profitLossDataAvailability?.earliestAvailableMonth}
              mostRecentAvailableMonth={
                profitLossDataAvailability?.mostRecentAvailableMonth
              }
            />
          )
        }
        default:
          return impossibleState(profitLossAvailability)
      }
    },
    [profitLossAvailability, selectedIntegrationId],
  )

  const onAccountSelect: React.ChangeEventHandler<HTMLSelectElement> = useCallback(
    (e) => {
      TrackingService.track(PNL_EVENTS.SELECT_ACCOUNT)
      setSelectedIntegrationId(e.target.value)
    },
    [],
  )

  return (
    <Stack spacing={[2, 4, 8]}>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing={[2, 4, 8]}
        alignItems="baseline"
        justifyContent="space-between"
      >
        <Heading as="h1" size="lg">
          Profit and Loss
        </Heading>
        {hasAtLeastOneIntegration && (
          <Text>
            <strong>Last updated:</strong> {utcDayJs().format('MMMM D, YYYY')}
          </Text>
        )}
      </Stack>
      {!hasAtLeastOneIntegration ? (
        <Box>
          <Button
            colorScheme="blue"
            leftIcon={<FaPlus />}
            onClick={() => {
              TrackingService.track(INTEGRATION_EVENTS.INTEGRATED_ACCOUNTING_STARTED)
              window.location.assign(process.env.REACT_APP_CODAT_LINK)
            }}
          >
            Connect accounting software
          </Button>
        </Box>
      ) : (
        <>
          <Divider />
          {isNotEmptyOrNullish(integrationsInProgress) &&
            `${integrationsInProgress.length} integration is in progress of downloading data...`}
          {codatIntegrationsPairs.length > 1 && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={[2, 4, 8]}
            >
              <FormControl id="account-selector" w={300}>
                <FormLabel>Selected account:</FormLabel>
                <Select value={selectedIntegrationId} onChange={onAccountSelect}>
                  {codatIntegrationsPairs.map((it) => (
                    <option key={it.integrationId} value={it.integrationId}>
                      {it.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          )}
        </>
      )}

      <ProfitLossPageDataBoundary />
    </Stack>
  )
}
