import type { Money } from '@liveflow-io/utils-common'
import type { ProfitLossTable_ProfitLossPayloadFragment } from '../pages/secured/ProfitLoss/documents.generated'

export type CategoriesToValuesOrNullPlaceholders = { [key: string]: (Money | null)[] }

export const mapCategoriesToValuesOrNullPlaceholders = (
  pnlKey: 'costOfSales' | 'income' | 'operatingCost' | 'otherExpenses' | 'otherIncome',
  profitLosses: ProfitLossTable_ProfitLossPayloadFragment[],
) => {
  const categories = [
    ...new Set(profitLosses.flatMap((pnl) => pnl[pnlKey].map((it) => it.name))),
  ]
  return categories.reduce<CategoriesToValuesOrNullPlaceholders>((acc, categoryName) => {
    const keyToValues = profitLosses.map(
      (pnl) => pnl[pnlKey].find((pred) => pred.name === categoryName)?.value ?? null,
    )
    return {
      ...acc,
      [categoryName]: keyToValues,
    }
  }, {})
}

export const buildMultipleItemsKeysToCellsMapping = (
  profitLosses: (ProfitLossTable_ProfitLossPayloadFragment | null | undefined)[] | null,
) => {
  if (profitLosses === null) {
    return null
  }
  const existingPnlsOnly = profitLosses.filter<ProfitLossTable_ProfitLossPayloadFragment>(
    // @ts-expect-error
    Boolean,
  )
  const costOfSales = mapCategoriesToValuesOrNullPlaceholders(
    'costOfSales',
    existingPnlsOnly,
  )
  const income = mapCategoriesToValuesOrNullPlaceholders('income', existingPnlsOnly)
  const operatingCost = mapCategoriesToValuesOrNullPlaceholders(
    'operatingCost',
    existingPnlsOnly,
  )
  const otherExpenses = mapCategoriesToValuesOrNullPlaceholders(
    'otherExpenses',
    existingPnlsOnly,
  )
  const otherIncome = mapCategoriesToValuesOrNullPlaceholders(
    'otherIncome',
    existingPnlsOnly,
  )
  return {
    costOfSales,
    income,
    operatingCost,
    otherExpenses,
    otherIncome,
  }
}
