import type { BoxProps } from '@chakra-ui/react'
import { chakra } from '@chakra-ui/react'
import type { AntdPickerProps } from './Base'
import { DatePickerBase } from './Base'
import type { Dayjs } from 'dayjs'

export type DatePickerProps = AntdPickerProps<Dayjs> &
  Omit<BoxProps, keyof AntdPickerProps<Dayjs>>

export const DatePicker = chakra(DatePickerBase)
