import { Button, Divider, Grid, Heading, Stack, Text } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import {
  Card,
  GenericError,
  GenericSpinner,
  LabeledInput,
} from '@liveflow-io/component-common'
import { IntegrateBankButton } from 'packlets/components'
import { useEnchancedQuery } from '@liveflow-io/hooks-common'
import { impossibleState } from '@liveflow-io/utils-common'
import { INTEGRATION_EVENTS, TrackingService } from 'packlets/utils'
import React from 'react'
import { FaPlus } from 'react-icons/fa'
import { CommonIntegrations_IntegrationPayloadDocument } from 'gql/queries/IntegrationsQuery.generated'

export const Integrations = () => {
  const { register, watch } = useForm<{ shopName: string }>({
    reValidateMode: 'onChange',
  })
  const shopName = watch('shopName')
  return (
    <Stack spacing={[4, 8]}>
      <Heading as="h1" size="lg">
        Integrations
      </Heading>
      <Divider />
      <Heading as="h2" size="md">
        Available integrations
      </Heading>
      <Stack direction={['column', 'row']}>
        <Card title="Plaid">
          <IntegrateBankButton />
        </Card>
        <Card>
          <Button
            leftIcon={<FaPlus />}
            onClick={() => {
              TrackingService.track(INTEGRATION_EVENTS.INTEGRATED_ACCOUNTING_STARTED)
              window.location.assign(process.env.REACT_APP_CODAT_LINK)
            }}
          >
            Connect accounting software
          </Button>
        </Card>
        {false && (
          <Card title="Shopify">
            <Stack spacing={2}>
              <LabeledInput
                id="shopName"
                label="Shop name (just shop name, without domain"
                name="shopName"
                placeholder="my-amazing-shop"
                ref={register({ required: true })}
              />

              <Button
                as="a"
                href={`/redirect/shopify/preinstall?shop=${shopName ?? ''}.myshopify.com`}
                isDisabled={shopName === ''}
              >
                Integrate with Shopify
              </Button>
            </Stack>
          </Card>
        )}
      </Stack>
      <Divider />
      <Heading as="h1" size="md">
        Installed integrations
      </Heading>
      <Grid gap={2} templateRows="1fr 1fr" templateColumns={['1fr', 'repeat(2, 1fr)']}>
        <IntegrationsList />
      </Grid>
    </Stack>
  )
}

const IntegrationsList = () => {
  const [integrationsMachine] = useEnchancedQuery({
    query: CommonIntegrations_IntegrationPayloadDocument,
  })

  switch (integrationsMachine.state) {
    case 'partial':
    case 'partial-stale':
    case 'error':
      return <GenericError />
    case 'fetching':
    case 'idle':
      return <GenericSpinner />
    case 'stale':
    case 'done': {
      const { integrations } = integrationsMachine.data
      return (
        <>
          {integrations.length !== 0 ? (
            integrations.map((it) => {
              return (
                <Card key={it.integrationId} minWidth={200}>
                  <Text>Source: {it.integrationSource}</Text>
                  {it.__typename === 'PlaidIntegrationPayload' ? (
                    <>
                      <Text>Bank: {it.bank}</Text>
                      <Text>Balance: {it.balance}</Text>
                    </>
                  ) : null}
                  {it.__typename === 'CommonAccountingIntegrationPayload' ? (
                    <>
                      <Text>{it.name}</Text>
                    </>
                  ) : null}
                </Card>
              )
            })
          ) : (
            <Text>No installed integrations</Text>
          )}
        </>
      )
    }
    default:
      return impossibleState(integrationsMachine)
  }
}
