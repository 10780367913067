import type { BoxProps } from '@chakra-ui/react'
import { chakra } from '@chakra-ui/react'
import type { AntdRangePickerProps } from './Base'
import { RangePickerBase } from './Base'
import type { Dayjs } from 'dayjs'

export const DateRangePicker = chakra(RangePickerBase)

export type DateRangePickerProps = AntdRangePickerProps<Dayjs> &
  Omit<BoxProps, keyof AntdRangePickerProps<Dayjs>>
