import type { BoxProps } from '@chakra-ui/react'
import { Box, Divider, Heading, Stack, Text } from '@chakra-ui/react'
import * as React from 'react'
import { EditCompanyForm } from './EditCompanyForm'
import { UserPreferences } from './UserPreferences'
import { PaymentInformation } from './PaymentInformation'

export type CardHeadingProps = {
  title: string
  children?: React.ReactNode
} & BoxProps

export const CardHeading = ({ title, children, ...rest }: CardHeadingProps) => {
  return (
    <Box {...rest}>
      <Text as="h3" fontWeight="bold" fontSize="lg">
        {title}
      </Text>
      <Text color="gray.500" fontSize="sm">
        {children}
      </Text>
    </Box>
  )
}

export const Settings = () => {
  return (
    <Stack spacing={8}>
      <Heading as="h1" size="lg">
        Settings
      </Heading>
      <Divider />
      <Stack spacing={8} direction={{ base: 'column', lg: 'row' }}>
        <EditCompanyForm />
        <Stack spacing={8} direction={{ base: 'column' }}>
          <UserPreferences />
          <PaymentInformation />
        </Stack>
      </Stack>
    </Stack>
  )
}
