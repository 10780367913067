import { Route, Routes } from 'react-router-dom'
import { SignInPage } from 'pages/SignIn'
import { AuthRedirect as AuthRedirectPage } from 'pages/AuthRedirect'
import { NotFound as NotFoundPage } from 'pages/NotFound'
import { ProtectedRoute } from 'packlets/components'
import { useListenToSystemTheme } from '@liveflow-io/hooks-common'
import { useAppcues } from 'packlets/hooks'
import { Box } from '@chakra-ui/react'
import { Routes as RoutesConstants } from 'packlets/constants'
import {
  BankDashboardPage,
  CashflowPage,
  CodatRedirect,
  ConsolidatedProfitLossPage,
  IntegrationsPage,
  InvitePage,
  NotEnabledPage,
  OnboardingPage,
  SecuredApp,
  SettingsPage,
  TrueLayerRedirect,
} from './secured'
import { ProfitLossPage } from './secured/ProfitLoss'

export const App = () => {
  useListenToSystemTheme()
  useAppcues()
  return (
    <Box h="100vh" position="relative">
      <Routes>
        <Route path="*" element={<NotFoundPage />} />
        <Route path={RoutesConstants.AUTH_REDIRECT} element={<AuthRedirectPage />} />
        <Route path={RoutesConstants.SIGN_IN} element={<SignInPage />} />
        <ProtectedRoute
          path={RoutesConstants.TRUELAYER_REDIRECT}
          element={<TrueLayerRedirect />}
        />
        <ProtectedRoute
          path={RoutesConstants.CODAT_REDIRECT}
          element={<CodatRedirect />}
        />
        <ProtectedRoute path={RoutesConstants.NOT_ENABLED} element={<NotEnabledPage />} />
        <ProtectedRoute path={RoutesConstants.ONBOARDING} element={<OnboardingPage />} />
        <ProtectedRoute path="/" element={<SecuredApp />}>
          <ProtectedRoute
            path={RoutesConstants.BANK_DASHBOARD}
            element={<BankDashboardPage />}
          />
          <ProtectedRoute
            path={RoutesConstants.CONSOLIDATED_PROFIT_LOSS}
            element={<ConsolidatedProfitLossPage />}
          />
          <ProtectedRoute
            path={RoutesConstants.PROFIT_LOSS}
            element={<ProfitLossPage />}
          />
          <ProtectedRoute path={RoutesConstants.CASH_FLOW} element={<CashflowPage />} />
          <ProtectedRoute
            path={RoutesConstants.INTEGRATIONS}
            element={<IntegrationsPage />}
          />
          <ProtectedRoute path={RoutesConstants.SETTINGS} element={<SettingsPage />} />
          <ProtectedRoute path={RoutesConstants.INVITE} element={<InvitePage />} />
        </ProtectedRoute>
      </Routes>
    </Box>
  )
}
