import React, { useCallback, useEffect } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { Button, Tooltip } from '@chakra-ui/react'
import type { ButtonProps } from '@chakra-ui/react'
import { noop, upperFirst } from 'lodash'
import type { PlaidButton_IntegrateMutation } from './documents.generated'
import {
  PlaidButton_LinkTokenDocument,
  PlaidButton_IntegrateDocument,
} from './documents.generated'

import type { UrqlOperationResultStates } from '@liveflow-io/hooks-common'
import { useEnchancedMutation, useToast } from '@liveflow-io/hooks-common'
import { impossibleState } from '@liveflow-io/utils-common'
import { useMutation } from 'urql'

type OnSuccessFunciton = (
  response: UrqlOperationResultStates<PlaidButton_IntegrateMutation>,
) => void

export const PlaidButton = ({
  onPublicTokenSubmitted = noop as any,
  isDisabled,
  ...props
}: {
  onPublicTokenSubmitted?: OnSuccessFunciton
} & ButtonProps) => {
  const [, integrateWithPlaid] = useEnchancedMutation(PlaidButton_IntegrateDocument)
  const toast = useToast()

  const [plaidLinkTokenResponse, getPlaidLinkToken] = useMutation(
    PlaidButton_LinkTokenDocument,
  )
  useEffect(() => {
    void getPlaidLinkToken()
  }, [getPlaidLinkToken])

  const onSuccess = useCallback(
    (token, metadata) => {
      integrateWithPlaid(
        {
          input: {
            publicToken: token,
            metadata,
          },
        },
        {
          additionalTypenames: ['PlaidIntegrationPayload'],
        },
      )
        .then((response) => {
          switch (response.state) {
            case 'partial':
            case 'error':
              toast({
                title: 'Something went wrong while integrating!',
                description: `Please try again! \n\n Error: ${JSON.stringify(
                  response.error,
                  null,
                  2,
                )}`,
              })
              break
            case 'done': {
              const data = response.data
              toast({
                title: `${upperFirst(
                  data.plaidIntegrate.bank ?? 'Your bank',
                )} was integrated successfully!`,
              })
              break
            }
            default:
              impossibleState(response)
          }
          onPublicTokenSubmitted(response)
          return response
        })
        .catch(console.error)
    },
    [toast, onPublicTokenSubmitted, integrateWithPlaid],
  )

  const config = {
    token:
      (plaidLinkTokenResponse.data && plaidLinkTokenResponse.data.plaidLinkTokenCreate) ??
      '',
    onSuccess,
  }

  const { open, ready, error } = usePlaidLink(config)

  if (error || plaidLinkTokenResponse.error) {
    return (
      <Button isDisabled colorScheme="red">
        Error fetching integration link!
      </Button>
    )
  }

  const isWaitingForPlaidLink =
    !ready || (plaidLinkTokenResponse.fetching && !plaidLinkTokenResponse.data)

  return (
    <Tooltip
      p={2}
      hasArrow
      label="We use Plaid to integrate your bank data.
Plaid Financial Ltd. is regulated by the Financial Conduct Authority under the Payment Service Regulations 2017 as an Authorised Payment Institution (Firm Reference Number: 804718 ) for the provision of payment services."
    >
      <Button
        {...props}
        onClick={(...args) => {
          props.onClick?.(...args)
          open()
        }}
        isLoading={isWaitingForPlaidLink}
        isDisabled={isWaitingForPlaidLink || isDisabled}
      >
        Connect your 🇺🇸 bank
      </Button>
    </Tooltip>
  )
}
