import React, { useEffect, useMemo } from 'react'
import {
  lightTheme as lt,
  darkTheme as dt,
  Grid,
  Axis,
  XYChart,
  Tooltip,
  buildChartTheme,
  BarSeries,
} from '@visx/xychart'
import { Text } from '@visx/text'
import type { ThemeConfig } from '@visx/xychart/lib/theme/buildChartTheme'
import { Box, useColorMode, Stack, useBreakpointValue } from '@chakra-ui/react'
import { ParentSize } from '@visx/responsive'
import type { MetricHistoryPoint, Money } from 'packlets/generated'
import { formatMoney, utcDayJs } from '@liveflow-io/utils-common'
import type { dayjs } from '@liveflow-io/utils-common'

const dateScaleConfig = { type: 'band', paddingInner: 0.5, paddingOuter: 0.2 } as const
const moneyScaleConfig = { type: 'linear' } as const

const formatWithoutYear = (date: dayjs.Dayjs) => date.format('MMM')
const formatWithYear = (date: dayjs.Dayjs) => date.format('MMM YYYY ')

const darkTheme = buildChartTheme(({
  ...dt,
  colors: ['#109CF1'],
} as unknown) as ThemeConfig)

const lightTheme = buildChartTheme(({
  ...lt,
  colors: ['#109CF1'],
} as unknown) as ThemeConfig)

export const BurnrateBarChart = ({ data }: { data: MetricHistoryPoint[] }) => {
  const config = useMemo(
    () => ({
      x: dateScaleConfig,
      y: moneyScaleConfig,
    }),
    [],
  )
  const { colorMode } = useColorMode()
  const theme = colorMode === 'light' ? lightTheme : darkTheme

  // This is hack to fix Tooltips (it should be recalculated with scroll event)
  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new CustomEvent('scroll'))
    }, 300)
  })

  const lastIndex = data.length - 1
  const dateFormatter =
    data.length >= 2 &&
    new Date(data[0].date).getFullYear() !== new Date(data[lastIndex].date).getFullYear()
      ? formatWithYear
      : formatWithoutYear

  const xTicks = useBreakpointValue({
    base: 2,
    sm: 3,
    md: 3,
    lg: 4,
    xl: 5,
  })

  return (
    <ParentSize>
      {({ width }) => (
        <XYChart
          theme={theme}
          xScale={config.x}
          yScale={config.y}
          height={300}
          width={width - 16}
        >
          <Grid
            rows
            columns={false}
            strokeDasharray="10, 5"
            // @ts-expect-error
            strokeOpacity={0.3}
          />
          <BarSeries
            dataKey="Cash in"
            data={data}
            xAccessor={(it) => dateFormatter(utcDayJs(it.date))}
            yAccessor={(it) => it.value.amount}
          />
          <Axis
            orientation="bottom"
            strokeWidth={0}
            numTicks={xTicks}
            hideAxisLine
            hideTicks
          />
          <Axis
            orientation="left"
            numTicks={5}
            hideAxisLine
            hideTicks
            tickComponent={({ formattedValue, ...props }) => (
              <Text {...props} dx={1}>
                {formatMoney(
                  Number(formattedValue?.replaceAll(',', '')?.replaceAll('.', '')),
                  {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                    notation: 'compact',
                  },
                )}
              </Text>
            )}
          />
          <Tooltip<{ date: string; value: Money }>
            showVerticalCrosshair
            snapTooltipToDatumX
            snapTooltipToDatumY
            verticalCrosshairStyle={{
              strokeOpacity: 0.4,
            }}
            showSeriesGlyphs
            glyphStyle={{
              r: 4,
              fill: colorMode === 'light' ? 'white' : 'black',
              strokeWidth: 1.5,
              stroke: '#109CF1',
            }}
            renderTooltip={({ tooltipData }) => (
              <Stack borderRadius={4}>
                <Box>
                  {tooltipData?.nearestDatum &&
                    dateFormatter(utcDayJs(tooltipData.nearestDatum?.datum.date))}
                </Box>
                <Box>
                  {formatMoney(tooltipData?.nearestDatum?.datum.value.amount ?? 0)}
                </Box>
              </Stack>
            )}
          />
        </XYChart>
      )}
    </ParentSize>
  )
}
