import {
  Heading,
  Stack,
  Text,
  SimpleGrid,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Select,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
  ModalBody,
  Modal,
} from '@chakra-ui/react'
import {
  useEnchancedMutation,
  useEnchancedQuery,
  useToast,
} from '@liveflow-io/hooks-common'
import {
  Card,
  GenericEmpty,
  GenericError,
  GenericSpinner,
} from '@liveflow-io/component-common'
import { impossibleState, utcDayJs } from '@liveflow-io/utils-common'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ConsolidatedProfitLossTable } from './ConsolidatedProfitLossTable'
import type { Dayjs } from 'dayjs'
import { FaPlus } from 'react-icons/fa'
import {
  CONSOLIDATED_PNL_EVENTS,
  INTEGRATION_EVENTS,
  TrackingService,
} from 'packlets/utils'
import { MonthPicker } from 'components'
import {
  ConsolidatedProfitLoss_ConsolidatedProfitLossAddedEntitiesDocument,
  ConsolidatedProfitLossTable_ProfitLossAvailabilityDocument,
  ConsolidateProfitLoss_AddEntityDocument,
} from './documents.generated'
import { useQuery } from 'urql'
import type { CommonIntegrations_CommonAccountingIntegrationPayloadFragment } from 'gql/queries/IntegrationsQuery.generated'
import { CommonIntegrations_IntegrationPayloadDocument } from 'gql/queries/IntegrationsQuery.generated'
import { useBoolean } from 'react-use'

const CURRENCIES = ['USD', 'GBP', 'PLN', 'CAD', 'CHF']
type FromToPair = [Dayjs | undefined, Dayjs | undefined]

export const ConsolidatedProfitLossPage = () => {
  const [integrations] = useQuery({
    query: CommonIntegrations_IntegrationPayloadDocument,
  })
  const [, addEntity] = useEnchancedMutation(ConsolidateProfitLoss_AddEntityDocument)
  const [addedEntitiesQuery] = useQuery({
    query: ConsolidatedProfitLoss_ConsolidatedProfitLossAddedEntitiesDocument,
  })
  const toast = useToast()
  const [profitLossAvailability, , availabilityQuery] = useEnchancedQuery({
    query: ConsolidatedProfitLossTable_ProfitLossAvailabilityDocument,
  })
  const [selectedCurrency, setCurrency] = useState<string>('USD')
  const [selectedMonth, setSelectedMonth] = useState<Dayjs>()

  const [dataAvailableEarliest, dataAvailableRecent] = useMemo<FromToPair>(() => {
    if (availabilityQuery.data?.profitLossConsolidatedDataAvailability) {
      const from = utcDayJs(
        availabilityQuery.data.profitLossConsolidatedDataAvailability
          .earliestAvailableMonth,
      )
      const to = utcDayJs(
        availabilityQuery.data.profitLossConsolidatedDataAvailability
          .mostRecentAvailableMonth,
      )
      return [from, to]
    }
    return [undefined, undefined]
  }, [availabilityQuery.data?.profitLossConsolidatedDataAvailability])

  const [isDataAvailableRecentSet, setIsDataAvailableRecentSet] = useBoolean(false)

  useEffect(() => {
    if (dataAvailableRecent && !isDataAvailableRecentSet) {
      setSelectedMonth(dataAvailableRecent)
      setIsDataAvailableRecentSet(true)
    }
  }, [dataAvailableRecent, isDataAvailableRecentSet, setIsDataAvailableRecentSet])

  const isNotBeforeRecentAndAfterEarliest = useCallback(
    (it: Dayjs) => {
      return dataAvailableEarliest && dataAvailableRecent
        ? !it.isBetween(dataAvailableEarliest, dataAvailableRecent, null, '[]')
        : true
    },
    [dataAvailableEarliest, dataAvailableRecent],
  )

  const integrationIdToNameMapping = useMemo(
    () =>
      addedEntitiesQuery.data?.profitLossConsolidatedAddedEntities?.reduce<
        Record<string, string>
      >((acc, it) => {
        return {
          ...acc,
          [it.integrationId]: it.name,
        }
      }, {}),
    [addedEntitiesQuery.data?.profitLossConsolidatedAddedEntities],
  )

  const codatIntegrations = useMemo(
    () =>
      integrations.data?.integrations.filter<CommonIntegrations_CommonAccountingIntegrationPayloadFragment>(
        (it): it is CommonIntegrations_CommonAccountingIntegrationPayloadFragment =>
          it.__typename === 'CommonAccountingIntegrationPayload',
      ),
    [integrations.data?.integrations],
  )

  const nonIntegratedEntities = codatIntegrations?.filter((it) => {
    return !addedEntitiesQuery.data?.profitLossConsolidatedAddedEntities?.find(
      (added) => added.integrationId === it.integrationId,
    )
  })

  const onCurrencyChange: React.ChangeEventHandler<HTMLSelectElement> = useCallback(
    (e) => {
      TrackingService.track(CONSOLIDATED_PNL_EVENTS.CURRENCY_CHANGED)
      setCurrency(e.target.value)
    },
    [],
  )

  const allEntitiesIntegrated = nonIntegratedEntities?.length === 0
  const hasAtLeastTwoIntegrations = (codatIntegrations?.length ?? 0) > 1

  const { isOpen, onOpen, onClose } = useDisclosure()
  const onAddEntity = async (integrationId: string) => {
    const response = await addEntity({ integrationId })
    switch (response.state) {
      case 'error':
        toast({
          status: 'error',
          title: 'Oh no, something went wrong!',
          description:
            'Unfortunately something unexpected happened, please, refresh the page..',
        })
        break
      case 'partial':
      case 'done':
        TrackingService.track(CONSOLIDATED_PNL_EVENTS.ADDED_ENTITY)
        toast({
          title: 'Success',
          description: 'Entity has been added to PnL!',
        })
        break
      default:
        impossibleState(response)
    }
    onClose()
  }

  const ConsolidatedProfitLossPageDataBoundary = useMemo(
    () => () => {
      switch (profitLossAvailability.state) {
        case 'idle':
        case 'fetching':
          return <GenericSpinner />
        case 'partial':
        case 'partial-stale':
        case 'error':
          return <GenericError />
        case 'stale':
        case 'done': {
          const { profitLossConsolidatedDataAvailability } = profitLossAvailability.data
          if (!profitLossConsolidatedDataAvailability) {
            return (
              <GenericEmpty
                message={
                  <Text>Please add entities to see consolidated profit and loss 📊 </Text>
                }
              />
            )
          }
          if (!selectedMonth || !integrationIdToNameMapping) {
            return <GenericSpinner />
          }
          return (
            <ConsolidatedProfitLossTable
              integrationIdToNameMapping={integrationIdToNameMapping}
              selectedCurrency={selectedCurrency}
              selectedMonth={selectedMonth.date(1).format('YYYY-MM-DD')}
            />
          )
        }
        default:
          return impossibleState(profitLossAvailability)
      }
    },
    [integrationIdToNameMapping, profitLossAvailability, selectedCurrency, selectedMonth],
  )

  return (
    <Stack spacing={[2, 4, 8]}>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing={[2, 4, 8]}
        alignItems="baseline"
        justifyContent="space-between"
      >
        <Heading as="h1" size="lg">
          Consolidated P&L
        </Heading>
        {hasAtLeastTwoIntegrations && (
          <Text>
            <strong>Last updated:</strong> {utcDayJs().utc().format('MMMM D, YYYY')}
          </Text>
        )}
      </Stack>
      <Divider />
      {hasAtLeastTwoIntegrations ? (
        <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
          <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
            <Stack w={300}>
              <Text>Change period:</Text>
              <MonthPicker
                borderRadius={8}
                size="large"
                allowClear={false}
                disabledDate={isNotBeforeRecentAndAfterEarliest}
                value={selectedMonth}
                onChange={(date) => {
                  TrackingService.track(CONSOLIDATED_PNL_EVENTS.DATE_FILTER_CHANGED)
                  date && setSelectedMonth(date)
                }}
              />
            </Stack>
            <Button
              colorScheme="blue"
              onClick={onOpen}
              isDisabled={allEntitiesIntegrated}
            >
              Add entity
            </Button>
          </Stack>
          <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
            <FormControl id="consolidated-currency" w={300}>
              <FormLabel>Consolidated currency:</FormLabel>
              <Select value={selectedCurrency} onChange={onCurrencyChange}>
                {CURRENCIES.map((it) => {
                  return (
                    <option key={it} value={it}>
                      {it}
                    </option>
                  )
                })}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      ) : (
        <Stack>
          <Text>
            You need to connect at least 2 accounting solutions to see consolidated PnL
          </Text>
          <Button
            colorScheme="blue"
            leftIcon={<FaPlus />}
            w={260}
            onClick={() => {
              TrackingService.track(INTEGRATION_EVENTS.INTEGRATED_ACCOUNTING_STARTED)
              window.location.assign(process.env.REACT_APP_CODAT_LINK)
            }}
          >
            Connect accounting software
          </Button>
        </Stack>
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select entity to add to Consolidated P&L</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={[2, 4, 8]}>
            <SimpleGrid columns={2} spacing={[2, 4, 8]}>
              {nonIntegratedEntities?.map((integration) => {
                return (
                  <Card
                    sx={{
                      userSelect: 'none',
                      cursor: 'pointer',
                      transition: 'transform 0.2s',
                    }}
                    _hover={{ transform: 'scale(1.03)' }}
                    _active={{ transform: 'scale(1.00)' }}
                    key={integration.integrationId}
                    onClick={() => onAddEntity(integration.integrationId)}
                  >
                    {integration.name}
                  </Card>
                )
              })}
            </SimpleGrid>
          </ModalBody>
        </ModalContent>
      </Modal>
      {hasAtLeastTwoIntegrations && <ConsolidatedProfitLossPageDataBoundary />}
    </Stack>
  )
}
