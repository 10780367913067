import { Box, useColorModeValue, Text, Stack } from '@chakra-ui/react'

import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { GenericError, GenericSpinner } from '@liveflow-io/component-common'
import { useCommon_MeUserQuery } from 'packlets/queries'
import { impossibleState, isNotEmptyOrNullish } from '@liveflow-io/utils-common'
import { Routes } from 'packlets/constants'
import React from 'react'
import { Navigation } from 'components'
import type { Company } from 'packlets/generated'

export const SecuredApp = () => {
  const bg = useColorModeValue('white', 'gray.800')
  const [machineMe] = useCommon_MeUserQuery()
  const location = useLocation()

  if (location.pathname === '/') {
    return <Navigate to={Routes.BANK_DASHBOARD} />
  }

  switch (machineMe.state) {
    case 'idle':
    case 'fetching':
      return <GenericSpinner />
    case 'error':
    case 'partial-stale':
    case 'partial':
      return <GenericError />
    case 'done':
    case 'stale': {
      const user = machineMe.data?.me
      const company = machineMe.data?.me?.company
      const isOnboarded = user?.isOnboarded
      const isEnabled = machineMe.data.enabled
      if (isOnboarded && isEnabled && company && isNotEmptyOrNullish(user?.email)) {
        return (
          <>
            <Box>
              <Navigation company={company as Company} />
            </Box>
            <Stack
              bg={bg}
              p="6"
              borderLeft={['0px', '0px', '256px solid']}
              h="full"
              justifyContent="space-between"
              spacing="8"
              minHeight={['full', 'full', '100vh']}
            >
              <Outlet />
              <Text fontSize="xs" color="gray.400" textAlign="center" mt={[2, 4]}>
                We encrypt your data at rest and in transit. We never modify or share your
                valuable financial data internally or externally due to our native
                database encryption and secure authentication. LiveFlow is secured with
                HTTPS, which uses TLS and certificates to ensure that your data is
                encrypted when transmitted between our services.
              </Text>
            </Stack>
          </>
        )
      }
      if (isEnabled && !isOnboarded) {
        return <Navigate to={Routes.ONBOARDING} />
      }
      return <Navigate to={Routes.NOT_ENABLED} />
    }
    default:
      return impossibleState(machineMe)
  }
}
