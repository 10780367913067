import {
  Button,
  HStack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react'
import { FaPencilAlt, FaPlus, FaTrash } from 'react-icons/fa'
import React, { useMemo } from 'react'
import { formatMoney, isNullish, utcDayJs } from '@liveflow-io/utils-common'
import type { BudgetType } from './Cashflow'

type BudgetsPerMonth = { [month: string]: BudgetType }

export const BudgetTable = ({
  budgets,
  onBudgetEditClick,
  onCreateBudgetModalOpen,
  onBudgetDeleteClick,
}: {
  budgets: BudgetType[]
  onCreateBudgetModalOpen: React.MouseEventHandler<HTMLButtonElement>
  onBudgetEditClick: (id: string) => void
  onBudgetDeleteClick: (id: string) => void
}) => {
  const revenueColor = useColorModeValue('green.500', 'green.300')
  const expensesColor = useColorModeValue('red.500', 'red.300')
  const budgetsPerMonth = useMemo(
    () =>
      budgets.reduce<BudgetsPerMonth>((acc, bt) => {
        return { ...acc, [utcDayJs(bt.startDate).format('MMMM YYYY')]: bt }
      }, {} as BudgetsPerMonth),
    [budgets],
  )
  const months = useMemo(() => Object.keys(budgetsPerMonth), [budgetsPerMonth])
  return (
    <Table>
      <Thead>
        <Tr>
          <Th aria-label="Period" borderRight="1px solid" borderRightColor="inherit">
            Period
          </Th>
          {months.map((month) => {
            const budget = budgetsPerMonth[month]
            return (
              <Th
                colSpan={3}
                key={month}
                borderRight="1px solid"
                borderRightColor="inherit"
              >
                <HStack
                  spacing={2}
                  width="100%"
                  alignItems="baseline"
                  justifyContent="center"
                >
                  <Text>{month} </Text>
                  <FaPencilAlt
                    cursor="pointer"
                    onClick={() => onBudgetEditClick(budget.id)}
                  />
                  <FaTrash
                    cursor="pointer"
                    onClick={() => onBudgetDeleteClick(budget.id)}
                  />
                </HStack>
              </Th>
            )
          })}
          <Th aria-label="Add new budget">
            <Button
              colorScheme="blue"
              leftIcon={<FaPlus />}
              onClick={onCreateBudgetModalOpen}
            >
              Add new budget
            </Button>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <Td
            data-label="Overview"
            fontWeight="bold"
            borderRight="1px solid"
            borderRightColor="inherit"
          >
            Overview
          </Td>
          {months.map((month) => {
            return (
              <React.Fragment key={month}>
                <Th isNumeric as="td">
                  Budget
                </Th>
                <Th isNumeric as="td">
                  Actuals
                </Th>
                <Th isNumeric as="td" borderRight="1px solid" borderRightColor="inherit">
                  Delta
                </Th>
              </React.Fragment>
            )
          })}
          <Td data-label="Empty column" />
        </Tr>
        <Tr>
          <Td
            data-label="Cash in"
            fontWeight="bold"
            borderRight="1px solid"
            borderRightColor="inherit"
          >
            Cash in
          </Td>
          {months.map((month) => {
            const actualsRevenue: number | undefined =
              // @ts-expect-error
              budgetsPerMonth[month].actuals?.cashIn?.amount
            const expectedCashIn = budgetsPerMonth[month].expectedCashIn
            const cashInDelta = budgetsPerMonth[month]?.cashInDelta
            const isPositive = cashInDelta != null && cashInDelta >= 0
            const deltaColor = isNullish(cashInDelta)
              ? undefined
              : isPositive
              ? revenueColor
              : expensesColor
            return (
              <React.Fragment key={month}>
                <Td isNumeric>{formatMoney(expectedCashIn ?? 0)}</Td>
                <Td isNumeric>{formatMoney(actualsRevenue ?? 0)}</Td>
                <Td
                  isNumeric
                  color={deltaColor}
                  borderRight="1px solid"
                  borderRightColor="inherit"
                >
                  {formatMoney(cashInDelta ?? 0)}
                </Td>
              </React.Fragment>
            )
          })}
          <Td data-label="Empty column" />
        </Tr>
        <Tr>
          <Td
            data-label="Cash out"
            fontWeight="bold"
            borderRight="1px solid"
            borderRightColor="inherit"
            borderBottom="none"
          >
            Cash out
          </Td>
          {months.map((month) => {
            const actualsExpenses: number | undefined =
              // @ts-expect-error
              budgetsPerMonth[month].actuals?.cashOut?.amount
            const expectedCashOut = budgetsPerMonth[month].expectedCashOut
            const cashOutDelta = budgetsPerMonth[month]?.cashOutDelta
            const isBad = cashOutDelta != null && cashOutDelta >= 0
            const deltaColor = isNullish(cashOutDelta)
              ? undefined
              : isBad
              ? expensesColor
              : revenueColor
            return (
              <React.Fragment key={month}>
                <Td isNumeric borderBottom="none">
                  {formatMoney(expectedCashOut ?? 0)}
                </Td>
                <Td isNumeric borderBottom="none">
                  {formatMoney(actualsExpenses ?? 0)}
                </Td>
                <Td
                  isNumeric
                  color={deltaColor}
                  borderRight="1px solid"
                  borderRightColor="inherit"
                  borderBottom="none"
                >
                  {formatMoney(cashOutDelta ?? 0)}
                </Td>
              </React.Fragment>
            )
          })}
          <Td data-label="Empty column" borderBottom="none" />
        </Tr>
      </Tbody>
    </Table>
  )
}
