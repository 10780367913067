/* eslint-disable */
// ####################################+################################################
// ################# THIS IS GENERATED FILE DO NOT TOUCH!!!!!!!!!!!!####################
// ####################################-################################################
import type {
  UUID,
  DateTime,
  Time,
  JSON,
  Timestamp,
  Email,
  PhoneNumber,
  URL,
  Any,
  GraphQLDate,
} from 'packlets/graphql/scalars'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** An RFC-3339 compliant Full Date Scalar */
  Date: GraphQLDate
  /** A type representing a formatted io.liveflow.types.Email */
  Email: Email
  /** A JSON scalar */
  JSON: JSON
  /** Returns timestamp */
  Timestamp: Timestamp
  /** A type representing a formatted java.util.UUID */
  UUID: UUID
}

export type Actuals = {
  status: UserHasBankIntegration
}

export type BankIntegrationPayload = {
  balance?: Maybe<Scalars['Float']>
  bank?: Maybe<Scalars['String']>
  integrationId: Scalars['UUID']
  integrationSource: IntegrationSource
  problems?: Maybe<Array<IntegrationProblem>>
  status: IntegrationStatus
}

export type Dashboard = {
  status: UserHasBankIntegration
}

export type History = {
  status: UserHasBankIntegration
}

export type IntegrationPayload = {
  integrationId: Scalars['UUID']
  integrationSource: IntegrationSource
  problems?: Maybe<Array<IntegrationProblem>>
  status: IntegrationStatus
}

export type EditCompanyProblemsType = CompanyInputValidationProblem

export type IntegrationPayloadUnion =
  | CommonAccountingIntegrationPayload
  | CommonBankIntegrationPayload
  | CommonIntegrationPayload
  | PlaidIntegrationPayload

export type IntegrationProblem = PlaidIntegrationProblem

export type ActualsNoData = Actuals & {
  __typename?: 'ActualsNoData'
  status: UserHasBankIntegration
}

export type ActualsNotIntegrated = Actuals & {
  __typename?: 'ActualsNotIntegrated'
  status: UserHasBankIntegration
}

export type ActualsReady = Actuals & {
  __typename?: 'ActualsReady'
  burnRate: BurnRate
  cashIn: CashIn
  cashOut: CashOut
  currencyCode?: Maybe<Scalars['String']>
  integrationSource?: Maybe<IntegrationSource>
  lastUpdated: LastUpdated
  runway: Runway
  status: UserHasBankIntegration
}

export type Budget = {
  __typename?: 'Budget'
  actuals: Actuals
  cashInDelta?: Maybe<Scalars['Float']>
  cashOutDelta?: Maybe<Scalars['Float']>
  createdDate: Scalars['Timestamp']
  endDate: Scalars['Date']
  expectedCashIn: Scalars['Float']
  expectedCashOut: Scalars['Float']
  id: Scalars['UUID']
  startDate: Scalars['Date']
  updatedDate: Scalars['Timestamp']
}

export type BudgetAlreadyExistsProblem = {
  __typename?: 'BudgetAlreadyExistsProblem'
  message: Scalars['String']
}

export type BurnRate = {
  __typename?: 'BurnRate'
  value: Money
}

export type CashIn = {
  __typename?: 'CashIn'
  value: Money
}

export type CashOut = {
  __typename?: 'CashOut'
  value: Money
}

export type CodatIntegrationPayload = {
  __typename?: 'CodatIntegrationPayload'
  status: CodatIntegrationStatus
}

export type CommonAccountingIntegrationPayload = IntegrationPayload & {
  __typename?: 'CommonAccountingIntegrationPayload'
  integrationId: Scalars['UUID']
  integrationSource: IntegrationSource
  name: Scalars['String']
  problems?: Maybe<Array<IntegrationProblem>>
  status: IntegrationStatus
}

export type CommonBankIntegrationPayload = BankIntegrationPayload &
  IntegrationPayload & {
    __typename?: 'CommonBankIntegrationPayload'
    balance: Scalars['Float']
    bank: Scalars['String']
    integrationId: Scalars['UUID']
    integrationSource: IntegrationSource
    problems?: Maybe<Array<IntegrationProblem>>
    status: IntegrationStatus
  }

export type CommonIntegrationPayload = IntegrationPayload & {
  __typename?: 'CommonIntegrationPayload'
  integrationId: Scalars['UUID']
  integrationSource: IntegrationSource
  problems?: Maybe<Array<IntegrationProblem>>
  status: IntegrationStatus
}

export type Company = {
  __typename?: 'Company'
  city?: Maybe<Scalars['String']>
  country: Scalars['String']
  createdDate: Scalars['Timestamp']
  email: Scalars['String']
  id: Scalars['UUID']
  name: Scalars['String']
  postCode?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  streetLine1?: Maybe<Scalars['String']>
  streetLine2?: Maybe<Scalars['String']>
  updatedDate: Scalars['Timestamp']
}

export type CompanyInputValidationProblem = {
  __typename?: 'CompanyInputValidationProblem'
  country?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type ConsolidatedProfitLoss = {
  __typename?: 'ConsolidatedProfitLoss'
  costOfSales: Array<ProfitLossItem>
  costOfSalesSum: Money
  currencyCode: Scalars['String']
  fromDate: Scalars['Date']
  grossMargin?: Maybe<Scalars['Float']>
  grossProfit: Money
  income: Array<ProfitLossItem>
  incomeSum: Money
  netOtherIncome: Money
  netProfit: Money
  netProfitMargin?: Maybe<Scalars['Float']>
  operatingCost: Array<ProfitLossItem>
  operatingCostSum: Money
  operatingProfit: Money
  operatingProfitMargin?: Maybe<Scalars['Float']>
  otherExpenses: Array<ProfitLossItem>
  otherExpensesSum: Money
  otherIncome: Array<ProfitLossItem>
  otherIncomeSum: Money
  toDate: Scalars['Date']
}

export type ConsolidatedProfitLossEntity = {
  __typename?: 'ConsolidatedProfitLossEntity'
  integrationId: Scalars['UUID']
  name: Scalars['String']
}

export type ConsolidatedProfitLossPayload = {
  __typename?: 'ConsolidatedProfitLossPayload'
  consolidatedProfitAndLoss: ConsolidatedProfitLoss
  entities: Array<ProfitLoss>
}

export type CreateBudgetPayload = {
  __typename?: 'CreateBudgetPayload'
  problem?: Maybe<BudgetAlreadyExistsProblem>
  result?: Maybe<Budget>
}

export type CreateCompanyPayload = {
  __typename?: 'CreateCompanyPayload'
  problem?: Maybe<CompanyInputValidationProblem>
  result?: Maybe<CreateCompanyResult>
}

export type CreateCompanyResult = {
  __typename?: 'CreateCompanyResult'
  company?: Maybe<Company>
  message?: Maybe<Scalars['String']>
  user: User
}

export type CreateUserPayload = {
  __typename?: 'CreateUserPayload'
  result: CreateUserResult
}

export type CreateUserResult = {
  __typename?: 'CreateUserResult'
  message: Scalars['String']
  user: User
}

export type DashboardNoData = Dashboard & {
  __typename?: 'DashboardNoData'
  status: UserHasBankIntegration
}

export type DashboardNoIntegrations = Dashboard & {
  __typename?: 'DashboardNoIntegrations'
  status: UserHasBankIntegration
}

export type DashboardReady = Dashboard & {
  __typename?: 'DashboardReady'
  actuals?: Maybe<ActualsReady>
  history: HistoryReady
  lastUpdated: LastUpdated
  status: UserHasBankIntegration
}

export type EditBudgetPayload = {
  __typename?: 'EditBudgetPayload'
  result?: Maybe<Budget>
}

export type EditCompanyPayload = {
  __typename?: 'EditCompanyPayload'
  problem?: Maybe<EditCompanyProblemsType>
  result?: Maybe<Company>
}

export type HistoryNoData = History & {
  __typename?: 'HistoryNoData'
  status: UserHasBankIntegration
}

export type HistoryNotIntegrated = History & {
  __typename?: 'HistoryNotIntegrated'
  status: UserHasBankIntegration
}

export type HistoryReady = History & {
  __typename?: 'HistoryReady'
  balance: Array<MetricHistoryPoint>
  burnRate: Array<MetricHistoryPoint>
  cashIn: Array<MetricHistoryPoint>
  cashOut: Array<MetricHistoryPoint>
  currencyCode?: Maybe<Scalars['String']>
  lastUpdated: LastUpdated
  runway: Array<MetricHistoryPoint>
  status: UserHasBankIntegration
}

export type InviteUserPayload = {
  __typename?: 'InviteUserPayload'
  code: Scalars['String']
}

export type LastUpdated = {
  __typename?: 'LastUpdated'
  value: Scalars['Date']
}

export type MetricHistoryPoint = {
  __typename?: 'MetricHistoryPoint'
  date: Scalars['Date']
  value: Money
}

export type Money = {
  __typename?: 'Money'
  amount: Scalars['Float']
  currency: Scalars['String']
}

export type Mutation = {
  __typename?: 'Mutation'
  /** create budget */
  budgetCreate: CreateBudgetPayload
  /** delete budget */
  budgetDelete: Scalars['UUID']
  /** edit budget */
  budgetEdit: EditBudgetPayload
  codatIntegrate: CodatIntegrationPayload
  companyEdit: EditCompanyPayload
  /** Creates company for logged in user */
  onboardingCompanyCreate: CreateCompanyPayload
  /** Creates user for logged in user */
  onboardingUserCreate: CreateUserPayload
  /** Integrates bank into our backend with Plaid a.k.a /get_access_token from this guide: https://plaid.com/docs/quickstart/#how-it-works */
  plaidIntegrate: PlaidIntegrationPayload
  /** Generates Plaid Link token a.k.a /get_link_token from this guide: https://plaid.com/docs/quickstart/#how-it-works */
  plaidLinkTokenCreate: Scalars['String']
  profitLossConsolidatedAddEntity: ProfitLossConsolidatedAddEntityPayload
  profitLossConsolidatedRemoveEntity: ProfitLossConsolidatedRemoveEntityPayload
  trueLayerIntegrate: TrueLayerIntegrationPayload
  /** create invite code */
  userAdministrationInviteUser: InviteUserPayload
  userSettingsEdit: User
}

export type MutationBudgetCreateArgs = {
  input: CreateBudgetInput
}

export type MutationBudgetDeleteArgs = {
  id: Scalars['UUID']
}

export type MutationBudgetEditArgs = {
  id: Scalars['UUID']
  input: EditBudgetInput
}

export type MutationCodatIntegrateArgs = {
  input: CodatIntegrateInput
}

export type MutationCompanyEditArgs = {
  input: EditCompanyInput
}

export type MutationOnboardingCompanyCreateArgs = {
  input: CreateCompanyInput
}

export type MutationOnboardingUserCreateArgs = {
  input: CreateUserInput
}

export type MutationPlaidIntegrateArgs = {
  input: PlaidIntegrateInput
}

export type MutationProfitLossConsolidatedAddEntityArgs = {
  integrationId: Scalars['UUID']
}

export type MutationProfitLossConsolidatedRemoveEntityArgs = {
  integrationId: Scalars['UUID']
}

export type MutationTrueLayerIntegrateArgs = {
  input: TrueLayerIntegrationInput
}

export type MutationUserAdministrationInviteUserArgs = {
  input: InviteUserInput
}

export type MutationUserSettingsEditArgs = {
  input: UserSettingsEditInput
}

export type PlaidIntegrationPayload = BankIntegrationPayload &
  IntegrationPayload & {
    __typename?: 'PlaidIntegrationPayload'
    balance?: Maybe<Scalars['Float']>
    bank?: Maybe<Scalars['String']>
    integrationId: Scalars['UUID']
    integrationSource: IntegrationSource
    problems?: Maybe<Array<PlaidIntegrationProblem>>
    status: IntegrationStatus
  }

export type PlaidIntegrationProblem = {
  __typename?: 'PlaidIntegrationProblem'
  institution: Scalars['JSON']
  message: Scalars['String']
}

export type ProfitLoss = {
  __typename?: 'ProfitLoss'
  costOfSales: Array<ProfitLossItem>
  costOfSalesSum: Money
  currencyCode: Scalars['String']
  fromDate: Scalars['Date']
  grossMargin?: Maybe<Scalars['Float']>
  grossProfit: Money
  income: Array<ProfitLossItem>
  incomeSum: Money
  integrationId: Scalars['UUID']
  netOtherIncome: Money
  netProfit: Money
  netProfitMargin?: Maybe<Scalars['Float']>
  operatingCost: Array<ProfitLossItem>
  operatingCostSum: Money
  operatingProfit: Money
  operatingProfitMargin?: Maybe<Scalars['Float']>
  otherExpenses: Array<ProfitLossItem>
  otherExpensesSum: Money
  otherIncome: Array<ProfitLossItem>
  otherIncomeSum: Money
  toDate: Scalars['Date']
}

export type ProfitLossConsolidatedAddEntityPayload = {
  __typename?: 'ProfitLossConsolidatedAddEntityPayload'
  integrationId: Scalars['UUID']
  message: Scalars['String']
}

export type ProfitLossConsolidatedRemoveEntityPayload = {
  __typename?: 'ProfitLossConsolidatedRemoveEntityPayload'
  integrationId: Scalars['UUID']
  message: Scalars['String']
}

export type ProfitLossDataAvailability = {
  __typename?: 'ProfitLossDataAvailability'
  earliestAvailableMonth: Scalars['Date']
  mostRecentAvailableMonth: Scalars['Date']
}

export type ProfitLossItem = {
  __typename?: 'ProfitLossItem'
  name: Scalars['String']
  value: Money
}

export type Query = {
  __typename?: 'Query'
  actuals: Actuals
  budget: Array<Budget>
  company: Company
  /** Fetches data for dashboard */
  dashboard: Dashboard
  enabled: Scalars['Boolean']
  history: History
  integrations: Array<IntegrationPayloadUnion>
  me?: Maybe<User>
  profitLoss?: Maybe<ProfitLoss>
  profitLossConsolidated?: Maybe<ConsolidatedProfitLossPayload>
  profitLossConsolidatedAddedEntities?: Maybe<Array<ConsolidatedProfitLossEntity>>
  profitLossConsolidatedDataAvailability?: Maybe<ProfitLossDataAvailability>
  profitLossDataAvailability?: Maybe<ProfitLossDataAvailability>
  subscription: Subscription
}

export type QueryActualsArgs = {
  input: ActualsInput
}

export type QueryDashboardArgs = {
  input: DashboardInput
}

export type QueryHistoryArgs = {
  input: HistoryInput
}

export type QueryProfitLossArgs = {
  input: ProfitLossInput
}

export type QueryProfitLossConsolidatedArgs = {
  input: ConsolidatedProfitLossInput
}

export type QueryProfitLossDataAvailabilityArgs = {
  input: ProfitLossAvailabilityInput
}

export type Runway = {
  __typename?: 'Runway'
  value: Money
}

export type Subscription = {
  __typename?: 'Subscription'
  sessionId: Scalars['String']
}

export type TrueLayerIntegrationPayload = {
  __typename?: 'TrueLayerIntegrationPayload'
  status: TrueLayerIntegrationStatus
}

export type User = {
  __typename?: 'User'
  company?: Maybe<Company>
  createdDate: Scalars['Timestamp']
  email: Scalars['Email']
  firstName?: Maybe<Scalars['String']>
  id: Scalars['UUID']
  idpUserId: Scalars['String']
  isOnboarded: Scalars['Boolean']
  settings: UserSettings
  updatedDate: Scalars['Timestamp']
}

export type UserSettings = {
  __typename?: 'UserSettings'
  isReducedMotion: Scalars['Boolean']
}

export enum CodatIntegrationStatus {
  Fail = 'FAIL',
  InProgress = 'IN_PROGRESS',
  Success = 'SUCCESS',
}

export enum IntegrationSource {
  Codat = 'CODAT',
  Plaid = 'PLAID',
  Shopify = 'SHOPIFY',
  TrueLayer = 'TRUE_LAYER',
}

export enum IntegrationStatus {
  Error = 'ERROR',
  InProgress = 'IN_PROGRESS',
  Success = 'SUCCESS',
}

export enum TrueLayerIntegrationStatus {
  Fail = 'FAIL',
  Success = 'SUCCESS',
}

export enum UserHasBankIntegration {
  NoData = 'NO_DATA',
  NoIntegrations = 'NO_INTEGRATIONS',
  Ready = 'READY',
}

export type ActualsFiltersInput = {
  range: RangeFilterInput
  source: IntegrationSource
}

export type ActualsInput = {
  filters: ActualsFiltersInput
}

export type CodatIntegrateInput = {
  codatCompanyId: Scalars['String']
}

export type ConsolidatedProfitLossInput = {
  month: Scalars['Date']
  selectedCurrency: Scalars['String']
}

export type CreateBudgetInput = {
  endDate: Scalars['Date']
  expectedCashIn: Scalars['Float']
  expectedCashOut: Scalars['Float']
  startDate: Scalars['Date']
}

export type CreateCompanyInput = {
  city?: Maybe<Scalars['String']>
  country: Scalars['String']
  name: Scalars['String']
  postCode?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  streetLine1?: Maybe<Scalars['String']>
  streetLine2?: Maybe<Scalars['String']>
}

export type CreateUserInput = {
  firstName: Scalars['String']
  inviteCode?: Maybe<Scalars['String']>
}

export type DashboardFiltersInput = {
  range: RangeFilterInput
  source: IntegrationSource
}

export type DashboardInput = {
  filters: DashboardFiltersInput
}

export type EditBudgetInput = {
  endDate: Scalars['Date']
  expectedCashIn: Scalars['Float']
  expectedCashOut: Scalars['Float']
  startDate: Scalars['Date']
}

export type EditCompanyInput = {
  city?: Maybe<Scalars['String']>
  country: Scalars['String']
  email: Scalars['String']
  name: Scalars['String']
  postCode?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  streetLine1?: Maybe<Scalars['String']>
  streetLine2?: Maybe<Scalars['String']>
}

export type HistoryFiltersInput = {
  range: RangeFilterInput
  source: IntegrationSource
}

export type HistoryInput = {
  filters: HistoryFiltersInput
}

export type InviteUserInput = {
  email: Scalars['Email']
}

export type PlaidIntegrateInput = {
  metadata: Scalars['JSON']
  publicToken: Scalars['String']
}

export type ProfitLossAvailabilityInput = {
  integrationId: Scalars['UUID']
}

export type ProfitLossInput = {
  endDate: Scalars['Date']
  integrationId: Scalars['UUID']
  selectedCurrency: Scalars['String']
  startDate: Scalars['Date']
}

export type RangeFilterInput = {
  from: Scalars['Date']
  to: Scalars['Date']
}

export type TrueLayerIntegrationInput = {
  authCode: Scalars['String']
}

export type UserSettingsEditInput = {
  isReducedMotion: Scalars['Boolean']
}
