import type { TypeOf } from 'zod'
import { object, string } from 'zod'
import { DASHBOARD_EVENTS, TrackingService } from 'packlets/utils'
import { useAtom } from 'jotai'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useCallback, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  Tooltip,
} from '@chakra-ui/react'
import { upperFirst } from 'lodash'
import { dashboardFilterAtom } from './BankDashboard'
import { isNotEmptyOrNullish, utcDayJs } from '@liveflow-io/utils-common'

const stringDate = string()
  .nonempty()
  .refine((it) => utcDayJs(it).isValid(), {
    message: 'Should be date of format YYYY-MM-DD',
  })

const checkNotAfterToday = (data: string) => {
  return utcDayJs(data).get('millisecond') <= new Date().getTime()
}

const dateFilterSchema = object({
  from: stringDate.refine(checkNotAfterToday, {
    message: 'Start date cannot be after today',
  }),
  to: stringDate.refine(checkNotAfterToday, {
    message: 'End date cannot be after today',
  }),
}).refine(
  (data) => {
    return new Date(data.from)?.getTime() <= new Date(data.to).getTime()
  },
  {
    message: 'Start date cannot be after the end date',
    path: ['from'], // set path of error
  },
)

type DateFilterType = TypeOf<typeof dateFilterSchema>

export const DashboardFilters = () => {
  const [{ from, to }, setFilter] = useAtom(dashboardFilterAtom)
  const { register, handleSubmit, errors, setValue, watch } = useForm<DateFilterType>({
    resolver: zodResolver(dateFilterSchema),
    defaultValues: {
      from,
      to,
    },
  })

  const onFilterApply = useCallback(() => {
    TrackingService.track(DASHBOARD_EVENTS.USED_DATE_FILTER)
    void setFilter({ to: watch('to'), from: watch('from') })
  }, [setFilter, watch])

  type RangesVariants = '7d' | '14d' | '30d' | '60d' | '90d' | '1y' | undefined
  const [selectedDateFilter, setDateFilter] = useState<RangesVariants>('90d')

  const updateFilters = useCallback(
    (dateFilter: RangesVariants) => {
      const setFilters = (aFrom: Date, aTo: Date) => {
        setValue('from', utcDayJs(aFrom).toGraphQLDate())
        setValue('to', utcDayJs(aTo).toGraphQLDate())
      }
      setDateFilter(dateFilter)
      switch (dateFilter) {
        case '7d':
          setFilters(utcDayJs().subtract(7, 'day').toDate(), utcDayJs().toDate())
          break
        case '14d':
          setFilters(utcDayJs().subtract(14, 'day').toDate(), utcDayJs().toDate())
          break
        case '30d':
          setFilters(utcDayJs().subtract(1, 'month').toDate(), utcDayJs().toDate())
          break
        case '60d':
          setFilters(utcDayJs().subtract(2, 'month').toDate(), utcDayJs().toDate())
          break
        case '90d':
          setFilters(utcDayJs().subtract(3, 'month').toDate(), utcDayJs().toDate())
          break
        case '1y':
          setFilters(utcDayJs().subtract(1, 'year').toDate(), utcDayJs().toDate())
          break
        case undefined:
        default:
          break
      }
    },
    [setValue],
  )

  return (
    <Stack
      direction={{ base: 'column', lg: 'row' }}
      spacing={[2, 4, 8]}
      alignItems="flex-end"
    >
      <Box>
        <Select
          width="150px"
          placeholder="Custom filter"
          value={selectedDateFilter}
          onChange={(e) => updateFilters(e.target.value as any)}
        >
          <option value="7d">Last week</option>
          <option value="14d">Last 2 weeks</option>
          <option value="30d">Last month</option>
          <option value="60d">Last 60 days</option>
          <option value="90d">Last Quarter</option>
          <option value="1y">Last year</option>
        </Select>
      </Box>
      <FormControl
        id="from-dashboard"
        isInvalid={isNotEmptyOrNullish(errors.from?.message)}
      >
        <FormLabel>Start date</FormLabel>
        <Tooltip
          label={upperFirst(errors.from?.message)}
          bg="red.500"
          isOpen={!!errors.from}
          hasArrow
          placement="bottom"
        >
          <Input type="date" name="from" placeholder="YYYY-MM-DD" ref={register} />
        </Tooltip>
      </FormControl>
      <FormControl id="to-dashboard" isInvalid={isNotEmptyOrNullish(errors.to?.message)}>
        <FormLabel>End date</FormLabel>
        <Tooltip
          label={upperFirst(errors.to?.message)}
          bg="red.500"
          isOpen={!!errors.to}
          hasArrow
          placement="bottom"
        >
          <Input type="date" name="to" placeholder="YYYY-MM-DD" ref={register} />
        </Tooltip>
      </FormControl>
      <Box>
        <Button colorScheme="blue" type="button" onClick={handleSubmit(onFilterApply)}>
          Apply filter
        </Button>
      </Box>
    </Stack>
  )
}
